import React from 'react';
import { useEventListener } from '@beef/react';

import { getCurrentBreakpoint, simulateResize } from './helpers';

/**
 * Update each time a resolution changes
 */
export const useAdaptiveSize = (breakpoints: number[]) => {
  const [breakpoint, setBreakpoint] = React.useState(getCurrentBreakpoint(breakpoints));
  const isWindowExist = () => typeof window !== 'undefined';

  React.useEffect(() => {
    simulateResize();
  }, [breakpoint]);

  useEventListener(
    'resize',
    React.useCallback(() => {
      /** return closest breakpoint that we surpassed in width */
      setBreakpoint(getCurrentBreakpoint(breakpoints));
    }, [breakpoints]),
    isWindowExist() ? window : global,
  );

  return breakpoint;
};
