import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { commonMetric } from 'pages/MainPage/metric/common';

import { IBusinessServicesBlock, IBusinessTab } from './types';
import { CardsCarousel } from '../../ui';
import { ChipsGroups } from './components/ChipsGroups';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BusinessServicesBlock: React.FC<IBusinessServicesBlock> = ({
  title,
  contentTabs,
  blockTitle,
}) => {
  const [tab, setTab] = useState<IBusinessTab>(contentTabs[0]);

  return (
    <div className={cx('block')}>
      <Text className={cx('heading-title')} color="primary" variant="headline-small">
        {title}
      </Text>
      <div className={cx('tabs')}>
        <ChipsGroups
          className={cx('tabs-group')}
          items={contentTabs}
          onChange={(currentTab) => {
            setTab(currentTab);
            commonMetric.handleTabClick(JSON.stringify({ tabText: currentTab.label, blockTitle }));
          }}
          value={tab}
        />
      </div>
      <CardsCarousel
        onCardClick={(cardTitle) =>
          commonMetric.handleTabCardClick(
            JSON.stringify({
              blockTitle,
              elementTitle: cardTitle,
              tabName: tab.label,
            }),
          )
        }
        slides={tab?.slides}
      />
    </div>
  );
};
