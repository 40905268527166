import { RootState, SectionType } from '../types';

export const selectMainSlider = (state: RootState) =>
  state.sectionsParsed[SectionType.MainSlider].content;

export const selectForYouSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForYou].content;
export const selectServiceSectionBlock = (state: RootState) =>
  state.sectionsParsed[SectionType.ServiceSection].content.serviceBlock;
export const selectAppSectionBlock = (state: RootState) =>
  state.sectionsParsed[SectionType.AppSection].content.appBlock;

export const selectForBusinessSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForBusiness].content;

export const selectForDevicesSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForDevices].content;
export const selectForFamilySection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForFamily].content;
export const selectForHomeSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForHome].content;
export const selectHRSection = (state: RootState) =>
  state.sectionsParsed[SectionType.HRSection].content;
export const selectHelpSection = (state: RootState) =>
  state.sectionsParsed[SectionType.HelpSection];

export const selectBreakpoint = (state: RootState) => state.breakpoint;
