export const BREAKPOINT_MAX = Number.MAX_VALUE;
export const BREAKPOINT_1920 = 1920;
export const BREAKPOINT_1680 = 1680;
export const BREAKPOINT_1440 = 1440;
export const BREAKPOINT_1280 = 1280;
export const BREAKPOINT_1100 = 1100;
export const BREAKPOINT_1025 = 1025;
export const BREAKPOINT_1024 = 1024;
export const BREAKPOINT_960 = 960;
export const BREAKPOINT_800 = 800;
export const BREAKPOINT_480 = 480;
export const BREAKPOINT_375 = 375;
export const BREAKPOINT_320 = 320;

export const breakpoints = [
  BREAKPOINT_MAX,
  BREAKPOINT_1920,
  BREAKPOINT_1680,
  BREAKPOINT_1440,
  BREAKPOINT_1280,
  BREAKPOINT_1100,
  BREAKPOINT_1025,
  BREAKPOINT_1024,
  BREAKPOINT_960,
  BREAKPOINT_800,
  BREAKPOINT_480,
  BREAKPOINT_375,
  BREAKPOINT_320,
];

export const BREAKPOINT_SET = 'SetBreakpoint';
