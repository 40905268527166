import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IFooter {
  priceText: string;
}

/** Футер карточки */
export const Footer: React.FC<IFooter> = ({ priceText }) => {
  return (
    <div className={cx('component')}>
      <Text color="primary-invert" size="xs">
        {priceText}
      </Text>
    </div>
  );
};
