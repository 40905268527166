import React from 'react';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { Metric } from 'pages/MainPage/metric';

import { AppBlockProps } from './types';
import { ContentBlock } from './components/ContentBlock';
import { StoresBlock } from './components/StoresBlock';
import { StickyIconBlock } from './components/StickyIconBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const AppBlock: React.FC<AppBlockProps> = ({
  content,
  stores,
  stickyImageUrl,
  phoneImageUrl,
}) => {
  const isMobile = useIsMobileDimension();
  return (
    <Metric
      asChild
      data={content?.title}
      events={{ commonMetric: ['handleBlockView'] }}
      mode="viewport"
      once
      threshold={0.5}
    >
      <div className={cx('app')}>
        {stickyImageUrl && !isMobile && <StickyIconBlock stickyImageUrl={stickyImageUrl} />}
        <ContentBlock {...content} {...stores.links} />
        <img alt="" className={cx('app-phone')} src={phoneImageUrl} />
        <div className={cx('app-store')}>
          <StoresBlock {...stores} blockTitle={content?.title} />
        </div>
      </div>
    </Metric>
  );
};
