import React from 'react';
import classNames from 'classnames/bind';
import { IconCloseRoundStroked } from '@carnica/graphics';
import { Cell } from '@carnica/ui-kit';

import { ImageCell } from '../../ImageCell/ImageCell';
import { CellCardProps } from '../types';
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

export const CellCard: React.FC<CellCardProps> = ({ item, onClear, onClick, metrics }) => {
  return (
    <li key={item.title} {...metrics}>
      <Cell
        className={cx('cell')}
        href={item?.href}
        leftSide={
          <ImageCell isSmall={!!item?.subtitle} src={item?.image_url} type={item.category} />
        }
        onClick={({ currentTarget: { innerText } }: { currentTarget: { innerText: string } }) =>
          onClick?.(innerText)
        }
        rightSide={
          onClear && (
            <IconCloseRoundStroked
              className={cx('icon-close')}
              color="secondary-invert"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e?.stopPropagation();
                onClear?.(item.title);
              }}
            />
          )
        }
        subTitle={item?.subtitle}
        tagName="a"
        title={item.title}
        view="clear"
      />
    </li>
  );
};
