import { YMMetricHelper } from '@beef/metric';
import { sanitize } from '@beef/utils';

import { ECard, RESULT_TEXT, TITLES_BLOCK } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { selectHelpBlockDataForMetric } from 'pages/MainPage/blocks/HelpBlock/metric/selectors';
import type { RootState } from 'pages/MainPage/types';
import {
  getListByCategory,
  selectPlaceholderHelp,
  selectTitleHelp,
} from 'pages/MainPage/store/slices/help/selectors';
import { getCommonPropsStore2024 } from 'utils/analytics/ymBase';

/* Ссылка на тз: https://bwiki.beeline.ru/pages/viewpage.action?pageId=635841267  */
export class HelpBlockMetric extends YMMetricHelper<
  RootState,
  ReturnType<typeof selectHelpBlockDataForMetric>
> {
  protected readonly HELP_BLOCK_PARAMS_KEY = 'main';

  protected setHelpBlockInfo = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.HELP_BLOCK_PARAMS_KEY]: {
          ...params,
        },
      };

    const commonPropsYM = getCommonPropsStore2024();

    return {
      [this.HELP_BLOCK_PARAMS_KEY]: {
        ...commonPropsYM,
        ...params,
      },
    };
  };

  protected getHelpBlockTitle = (): string => {
    const state = this.getData();

    return state ? selectTitleHelp(state)?.replaceAll('&nbsp;', ' ') : '';
  };

  protected getPlaceholderSearch = (): string => {
    const state = this.getData();

    return state ? selectPlaceholderHelp(state) : '';
  };

  protected getSearchListByCategory = (categoryTitle: ECard): string => {
    const state = this.getData();

    return state ? getListByCategory(state, categoryTitle)?.join() : '[]';
  };

  /* п.1 Просмотр блока "Помощи" */
  handleViewHelpBlock() {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'block',
        block_title: this.getHelpBlockTitle(),
      }),
    );
  }

  /* п.3 Просмотр модалки поиска */
  handleViewHelpModal() {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'popup',
        popup_name: 'popup_search',
        object_type: 'block',
        block_title: this.getHelpBlockTitle(),
      }),
    );
  }

  /* п.4 Клик по чипсинам блока */
  handleChipsBlockClick(tab: string) {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'click',
        item_type: 'tab',
        tab_text: tab,
        object_type: 'block',
        block_title: this.getHelpBlockTitle(),
      }),
    );
  }

  /* п.5 Клик по блоку с Чатом поддержки (Особенный вопрос?) */
  handleChatButtonClick(text: string) {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'click',
        item_type: 'button',
        button_text: text,
        object_type: 'block',
        block_title: this.getHelpBlockTitle(),
      }),
    );
  }

  /* п.6 Просмотр блока "советуем посмотреть" */
  handleViewRecently() {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'block',
        block_title: TITLES_BLOCK.recently,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }

  /* п.7 Ввод запроса */
  handleFillInSearch() {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'fill_in',
        item_type: 'field',
        field_name: this.getPlaceholderSearch(),
        field_text: this.getData()?.helpSlice.query,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }

  handleViewCategoryBlock(categoryTitle: ECard) {
    /* п.8 Просмотр каждой блока в категориях */
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'block',
        block_title: categoryTitle,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );

    /* п.11 Просмотр списка результатов */
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'element',
        element_title: this.getSearchListByCategory(categoryTitle),
        element_type: categoryTitle,
        field_text: this.getData()?.helpSlice.query,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }

  /* п.9 Клик по результатам поиска */
  handleCategoryElementClick(data: string) {
    const { elementTitle, categoryTitle } = JSON.parse(data);

    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'click',
        item_type: 'element',
        element_title: elementTitle,
        block_title: categoryTitle,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }

  /* п.12 Клик по "Все результаты" */
  handleButtonAllResultClick() {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'click',
        item_type: 'button',
        button_text: RESULT_TEXT,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }

  /* п.13 Просмотр "Ничего не найдено" */
  handleViewEmptyStatus(text: string) {
    this.sendParamsEvent(
      this.setHelpBlockInfo({
        action: 'view',
        item_type: 'notification',
        notification_text: sanitize(text),
        field_text: this.getData()?.helpSlice.query,
        object_type: 'popup',
        popup_name: 'popup_search',
      }),
    );
  }
}

export const helpBlockMetric = new HelpBlockMetric({
  counterId: 26001372,
  selector: selectHelpBlockDataForMetric,
  isEcommerce: false,
  debug: true,
});
