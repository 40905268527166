import { compose } from '@beef/redux';
import { FC } from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { RootState } from 'pages/MainPage/types';
import { selectError, selectIsEmptyResponse } from 'pages/MainPage/store/slices/help/selectors';
import { NONE_SCREEN } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';

import { StatusScreen } from './StatusScreen';

export const Empty = compose<FC>(
  connect((state: RootState) => ({
    text: NONE_SCREEN.contentEmptyText,
    shouldRender: selectIsEmptyResponse(state),
  })),
  withRenderFlag,
)(StatusScreen);
export const Error = compose<FC>(
  connect((state: RootState) => ({
    text: NONE_SCREEN.contentErrorText,
    shouldRender: !!selectError(state).message,
  })),
  withRenderFlag,
)(StatusScreen);
