import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';

import { StatusScreenType } from './types';
import Image from './assets/error.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const StatusScreen: FC<StatusScreenType> = ({ text }) => {
  return (
    <Metric
      asChild
      data={text}
      events={{ helpBlockMetric: ['handleViewEmptyStatus'] }}
      mode="render"
    >
      <section className={cx('error')}>
        <div className={cx('error-content')}>
          <Image />
          {text && (
            <Text align="center" className={cx('error-content__text')} color="tertiary" size="xs">
              {text}
            </Text>
          )}
        </div>
      </section>
    </Metric>
  );
};
