import { Button } from '@carnica/ui-kit';
import React from 'react';
import classNames from 'classnames/bind';
import { MenuUrlTarget } from '@carnica/smart-kit';

import Icon from './components/Icon';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type ButtonLinkType = {
  arrowFill?: 'primary' | 'secondary';
  arrowPosition?:
    | 'bottom-left'
    | 'bottom-right'
    | 'down'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'up';
  children: React.ReactNode;
  className?: string;
  href?: string;
  isBlankUrlTarget?: boolean;
  labelColor?: 'primary' | 'secondary' | 'tertiary';
  labelWeight?: 'bold' | 'light' | 'medium' | 'normal';
  onClick?: () => void;
};

export const ButtonArrow: React.FC<ButtonLinkType> = (props) => {
  const {
    arrowFill = 'white',
    arrowPosition = 'right',
    children,
    onClick,
    className,
    labelColor = 'primary',
    labelWeight = 'normal',
    href,
    isBlankUrlTarget,
    ...otherProps
  } = props;

  const Tag = href ? 'a' : Button;

  return (
    <Tag
      className={cx('button', className)}
      href={href}
      mode="inline"
      onClick={onClick}
      target={MenuUrlTarget.get(isBlankUrlTarget as boolean)}
      {...otherProps}
    >
      <span
        className={cx('button-wrap', `text-color--${labelColor}`, `text-weight--${labelWeight}`)}
      >
        <span className={cx('button-content')}>{children}</span>
        <span className={cx('icon', `icon-fill--${arrowFill}`)}>
          <Icon color={labelColor} condition={arrowPosition} />
        </span>
      </span>
    </Tag>
  );
};
