import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { FC } from 'react';
import { withRenderFlag } from '@beef/react';

import { selectHelpSection } from 'pages/MainPage/store/selectors';
import {
  selectAnchorHelpBlock,
  selectIsRenderBlock,
} from 'pages/MainPage/store/slices/help/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    ...selectHelpSection(state),
    shouldRender: selectIsRenderBlock(state),
    anchor: selectAnchorHelpBlock(state),
  };
};

export const connector = compose<FC>(connect(mapStateToProps), withRenderFlag);
