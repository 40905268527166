import React from 'react';
import classNames from 'classnames/bind';

import style from './styles.pcss';
import { BrandCard, BrandType } from './components/BrandCard';

const cn = classNames.bind(style);

export type BrandsPropsType = {
  brands: BrandType[];
};

export const Brands: React.FC<BrandsPropsType> = ({ brands }): JSX.Element =>
  brands ?
    <ul className={cn('brands')}>
      {brands.map((brand: BrandType) => (
        <BrandCard {...brand} key={brand.href} />
      ))}
    </ul>
  : null;
