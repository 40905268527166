import { isInPage } from 'utils/isInPage';

export const scrollToBlock = (elementId: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const element = document.getElementById(elementId);

  if (element && isInPage(element)) {
    const topOffset = element.getBoundingClientRect().top + window.scrollY - 80;
    window.scrollTo({ top: topOffset, behavior: 'smooth' });
  }
};
