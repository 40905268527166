import {
  IconBagOutline,
  IconDocFillOutline,
  IconLayersOutline,
  IconSimOutline,
  IconTimeOutline,
  IconUserOutline,
} from '@carnica/graphics';

import { DefaultIconsType } from './types';
import { CARD_TYPE } from '../../ulils/constants';

export const DEFAULT_ICONS: { [key: string]: DefaultIconsType } = {
  [CARD_TYPE.elk]: IconUserOutline,
  [CARD_TYPE.other]: IconDocFillOutline,
  [CARD_TYPE.service]: IconLayersOutline,
  [CARD_TYPE.tariff]: IconSimOutline,
  [CARD_TYPE.product]: IconBagOutline,
  [CARD_TYPE.history]: IconTimeOutline,
};
