import { withDataConverter } from '@beef/react';
import { compose } from '@beef/utils';
import React from 'react';
import { connect } from 'react-redux';

import { getUserData } from '../../../../store/feature/userInfo/selectors';
import { PaymentWidget as _PaymentWidget } from './PaymentWidget';
import { setInputState } from './components/Input/store';
import { withPaymentWidget } from './enhancers/withPaymentWidget';
import { composeRoot } from './root';
import { getInitialCtn, setContent } from './store';
import { TPaymentWidgetProps } from './types';

const { converter, onStateChange } = composeRoot();

export const PaymentWidget = compose<React.FC<Partial<TPaymentWidgetProps>>>(
  connect((state) => ({
    userInfo: getUserData(state),
  })),
  withPaymentWidget({
    setContent,
    setInputState,
    getInitialCtn: () => {
      // TODO: Узнать как брать номер телефона в новых лендингах
      // const _ctn = headerStore2024()?.getState()?.header?.common?.auth?.user?.ctn;
      return getInitialCtn();
    },
    onStateChange,
  }),
  withDataConverter(converter),
)(_PaymentWidget);
