import { CSSProperties } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ICardTitle } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { useTariffCardBlockType } from 'pages/MainPage/blocks/TariffCardBlock/components/Container/hooks/useTariffCardBlockType';

interface IUsePrepadeCard {
  bgImage?: string;
  cardTitle?: ICardTitle;
}

/** Хук подготовки карточки */
export const usePrepareTTariffCard = ({ cardTitle, bgImage }: IUsePrepadeCard) => {
  const isMobile = useIsMobileDimension();
  const { mobile, tablet } = cardTitle || {};

  /* Вариант текста для мобилки если он есть при мобильном vw */
  const label = (isMobile && mobile) || tablet;

  /* Добавляет bg */
  const style: CSSProperties = {
    ...(bgImage ? { backgroundImage: `url(${bgImage})` } : {}),
  };

  /* Для метрики */
  const metricData = useTariffCardBlockType();

  return { label, style, metricData };
};
