import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { IGift } from 'pages/MainPage/blocks/TariffCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IInfo {
  gifts: IGift[];
}

/** Блок с подарками */
export const Gifts: React.FC<IInfo> = ({ gifts }) => (
  <div className={cx('container')}>
    {gifts.map(({ icon, title, label }) => (
      <div className={cx('info')} key={label}>
        <img alt="" src={icon} />
        <div className={cx('block')}>
          <Text color="primary-invert" size="2xs">
            {title}
          </Text>
          <Text color="secondary-invert" size="2xs">
            {label}
          </Text>
        </div>
      </div>
    ))}
  </div>
);
