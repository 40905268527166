import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={cx('section')}>{children}</div>;
};
