import React from 'react';
import { Chips } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';

import { TapsType } from './types';

export const ChipsElement: React.FC<{ handleClick: (value: string) => void; item: TapsType }> = ({
  item,
  handleClick,
}) => {
  return (
    <Metric
      asChild
      data={item.label}
      events={{ helpBlockMetric: ['handleChipsBlockClick'] }}
      mode="click"
    >
      <Chips key={item.label} label={item.label} onClick={() => handleClick(item.label)} />
    </Metric>
  );
};
