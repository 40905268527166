import { useEffect, useState } from 'react';
import { axiosBeelineRequest } from '@beef/utils';
import { useDispatch } from 'react-redux';

import { ISection } from 'pages/MainPage/types';
import { mergeSections } from 'pages/MainPage/store/slices/sections';
import { reduceSections } from 'pages/MainPage/utils/utils';

const API_URL = '/customers/products/blocks2024/';

const fetchData = async (): Promise<{ sections: ISection[] }> =>
  axiosBeelineRequest({ url: API_URL, method: 'get' });

export const usePrepareSectionBlocks = () => {
  const [result, setResult] = useState<ISection[] | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData()
      .then(({ sections }) => {
        const sectionsParsed = reduceSections(sections);

        dispatch(mergeSections(sectionsParsed));
        setResult(sections);
      })
      .catch(() => setResult(null));
  }, []);

  return result;
};
