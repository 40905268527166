import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { IChips } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { Chips } from 'pages/MainPage/blocks/EthernetCardBlock/components/Chips';
import { commonMetric } from 'pages/MainPage/metric/common';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - блок с чипсами */
export const ChipsBlock: React.FC<IChips> = ({ title, items, blockTitle }) => {
  if (!items?.length) {
    return null;
  }
  return (
    <div className={cx('wrapper')}>
      <Text className={cx('text')} variant="body-medium">
        {title}
      </Text>
      {/* TODO: хорошим тоном читается вынос item'ов из родителя, то есть лучше во внешнем компоненте делать */}
      <div className={cx('block')}>
        {items.map((chips) => (
          <Chips
            key={chips.title}
            {...chips}
            onClick={() =>
              commonMetric.handleButtonClick(
                JSON.stringify({ blockTitle, buttonText: chips?.title }),
              )
            }
          />
        ))}
      </div>
    </div>
  );
};
