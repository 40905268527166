export enum ETextConstant {
  Title = 'пополни баланс\n телефона или интернета',
  ButtonText = 'пополнить',
  ButtonUrl = 'https://beeline.ru/customers/products/replenishment-balance/',
  InputPlaceholder = 'номер телефона или договора',
  InputPlaceholderMobile = 'номер телефона / договора',
  InputLabel = '',
  PrimaryDescription = 'баланс не только для связи',
  SecondaryDescription = 'оплачивай&nbspAppStore,&nbspAppGallery, PlayStation,&nbspSpotify&nbsp—&nbspа&nbspещё парковки, ЖКХ&nbspи&nbspпереводы&nbspна&nbspкарту',
  InlineButtonUrl = 'https://beeline.ru/customers/products/mobilnyy-platezh/',
  InlineButtonText = 'подробнее',
}
