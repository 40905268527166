import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { RootState } from 'pages/MainPage/types';
import { selectTitleHelp } from 'pages/MainPage/store/slices/help/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _TitleHelp: React.FC<{ title?: string }> = ({ title }) => {
  const isMobile = useIsMobileDimension();

  return (
    <Text
      align={isMobile ? 'center' : 'left'}
      className={cx('help-title')}
      variant={isMobile ? 'display-small' : 'display-medium'}
    >
      {title}
    </Text>
  );
};

export const TitleHelp = compose<FC>(
  connect((state: RootState) => ({
    title: selectTitleHelp(state),
  })),
)(_TitleHelp);
