import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ScrollableWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <section className={cx('scrollable-result-block')}>{children}</section>;
};
