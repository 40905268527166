import React from 'react';

export interface IStoreLinks {
  appGallery: string;
  appStore: string;
  googlePlay: string;
  ruStore: string;
}
export interface IContainerProps {
  children: React.ReactNode;
}
export interface IRenderFlag {
  children: React.ReactNode;
  shouldRender: string;
}
export interface IComponentStoreLinks {
  blockTitle?: string;
  icons: {
    appGallery: string;
    appStore: string;
    appStoreFull: string;
    googleStore: string;
    ruStore: string;
  };
  storeLinks: IStoreLinks;
}

export enum OS {
  Android = 'android',
  IOS = 'ios',
}

export enum Stores {
  Android = 'googlePlay',
  Huawei = 'appGallery',
  IOS = 'appStore',
  RuStore = 'ruStore',
}
