import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';

import { Header } from 'pages/MainPage/blocks/EthernetCardBlock/components/Header';
import { Footer } from 'pages/MainPage/blocks/EthernetCardBlock/components/Footer';
import { CardDescription } from 'pages/MainPage/blocks/EthernetCardBlock/components/CardDescription';
import { Options } from 'pages/MainPage/blocks/EthernetCardBlock/components/Options';
import { Gifts } from 'pages/MainPage/blocks/EthernetCardBlock/components/Gifts';
import { ConnectButton } from 'pages/MainPage/blocks/EthernetCardBlock/components/ConnectButton';
import { ITariffCard } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент карточки тарифа */
export const TariffCard: FC<ITariffCard> = ({
  bgImage,
  cardTitle,
  options,
  gifts,
  tagLabel,
  presetText,
  button,
  priceText,
  partnerLogo,
  subtitle,
  blockTitle,
}) => {
  const style: CSSProperties = {
    ...(bgImage ? { backgroundImage: `url(${bgImage})` } : {}),
  };

  return (
    <div className={cx('wrapper')}>
      {cardTitle && <CardDescription label={cardTitle} />}
      <div className={cx('content', !cardTitle && 'content--no-offset')} style={style}>
        <Header {...{ partnerLogo, presetText, tagLabel, subtitle }} />
        <Options {...options} />
        {gifts?.length && <Gifts gifts={gifts} />}
        <Footer {...priceText} />
      </div>
      <Metric
        asChild
        data={JSON.stringify({ blockTitle, buttonText: button?.text })}
        events={{ commonMetric: ['handleButtonClick'] }}
        mode="click"
      >
        <ConnectButton {...button} />
      </Metric>
    </div>
  );
};
