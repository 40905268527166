import { RefObject, useEffect } from 'react';

import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';

type useOnClickOutsideSearchType = {
  handler?: (event: KeyboardEvent | MouseEvent | TouchEvent) => void;
  isOpen?: boolean;
  ref: RefObject<any>;
};
export const useEventOutsideSearch = ({
  ref,
  handler,
  isOpen,
}: useOnClickOutsideSearchType): void => {
  const isDesktop = useIsDesktopDimension();
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref?.current || ref?.current.contains(event.target)) {
        return;
      }

      if (isDesktop && isOpen && !ref?.current.contains(event.target)) {
        handler?.(event);
      }
    };
    const listenerTab = (event: KeyboardEvent) => {
      if (!ref?.current || ref?.current.contains(event.target)) {
        return;
      }

      if (isDesktop && isOpen && !ref?.current.contains(event.target) && event.code === 'Tab') {
        handler?.(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('keyup', listenerTab);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('keyup', listenerTab);
    };
  }, [handler, isOpen]);
};
