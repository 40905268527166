import { connect } from 'react-redux';

import { RootState } from 'pages/MainPage/types';
import { selectIsLoading } from 'pages/MainPage/store/slices/help/selectors';

import { SkeletonLayout } from './SkeletonLayout';

const mapStateToProps = (state: RootState) => ({
  isLoading: selectIsLoading(state),
});

export const SkeletonLayoutRC = connect(mapStateToProps)(SkeletonLayout);
