import React from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';
import { Container } from '@carnica/smart-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import {
  AppPromoteBlock,
  AppPromoteBlockProps,
} from 'pages/MainPage/components/MnpBlock/component/AppPromoteBlock';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface MnpBlockProps {
  appPromoteBlock: AppPromoteBlockProps;
  blockTitle: string;
  buttonText: string;
  desktopIcon: string;
  href: string;
  isBlankUrlTarget: boolean;
  mobileIcon: string;
  subtitle: string;
  title: string;
}

export const MnpBlock: React.FC<MnpBlockProps> = ({
  title,
  subtitle,
  buttonText,
  mobileIcon,
  desktopIcon,
  href,
  isBlankUrlTarget,
  appPromoteBlock,
  blockTitle,
}) => {
  const isMobile = useIsMobileDimension();

  return (
    <Container.Flex
      className={cx('wrapper')}
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
    >
      <div className={cx('content')}>
        <Text className={cx('title')} variant="display-small">
          {title}
        </Text>
        <Text className={cx('subtitle')} color="secondary" variant="body-small">
          {subtitle}
        </Text>
        <Metric
          asChild
          data={JSON.stringify({ blockTitle, buttonText })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <Button
            className={cx('button')}
            href={href}
            tagName="a"
            target={MenuUrlTarget.get(isBlankUrlTarget)}
            width={isMobile ? 'full' : 'default'}
          >
            {buttonText}
          </Button>
        </Metric>
        {isMobile && <img alt="" className={cx('icon')} src={mobileIcon} />}
      </div>
      {!isMobile && <img alt="" className={cx('icon')} src={desktopIcon} />}

      <AppPromoteBlock {...appPromoteBlock} blockTitle={blockTitle} />
    </Container.Flex>
  );
};
