import React from 'react';
import classNames from 'classnames/bind';

import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Container: React.FC<{ children?: React.ReactNode; className?: string }> = ({
  className,
  children,
}) => {
  return (
    <Metric
      asChild
      events={{ helpBlockMetric: ['handleViewHelpModal'] }}
      mode="viewport"
      once
      threshold={0.2}
    >
      <div className={cx('search-container', className)}>{children}</div>
    </Metric>
  );
};

export const Section = ({
  children,
  isBottomFixed = false,
}: {
  children: React.ReactNode;
  isBottomFixed?: boolean;
}) => {
  return (
    <section
      className={cx('wrapper-section', {
        'wrapper-section__fixed-bottom': isBottomFixed,
      })}
    >
      {children}
    </section>
  );
};
