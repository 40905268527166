import { cnBem, combineRefs } from '@carnica/utils';
import React, { forwardRef, useRef } from 'react';
import { useOnClickOutside } from '@beef/ui-kit/hooks';

import styles from './styles.pcss';
import { ModalContentProps } from './types';

const cnModalContent = cnBem('modal-content', styles);

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>((props, ref) => {
  const {
    style,
    animate,
    onClose,
    closeOptions,
    isOpen,
    children,
    className,
    fullWidthContent,
    actionBarNode,
    overlayRef,
    disableOutsideClickHandler,
    ...otherProps
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: containerRef,
    handler: !disableOutsideClickHandler && onClose ? onClose : () => {},
    isOpen,
    overlayRef,
  });

  return (
    <div
      {...otherProps}
      className={cnModalContent({ animate, withCloseButton: !!onClose, fullWidthContent }, [
        className,
      ])}
      ref={combineRefs([containerRef, ref])}
      style={{
        ...style,
        // Нужно чтоб несколько модалок точно нормально отрисовывались друг над другом
        zIndex: undefined,
      }}
    >
      {actionBarNode && <div className={cnModalContent('action-bar')}>{actionBarNode}</div>}
      <div className={cnModalContent('wrapper')}>{children}</div>
    </div>
  );
});
