import React from 'react';

import { ContainerProvider } from 'pages/MainPage/blocks/TariffCardBlock/components/Container';
import { Title } from 'pages/MainPage/blocks/TariffCardBlock/components/Title';
import { TariffCard } from 'pages/MainPage/blocks/TariffCardBlock/components/TariffCard';
import { ChipsBlock } from 'pages/MainPage/blocks/TariffCardBlock/components/ChipsBlock';
import { Skeleton } from 'pages/MainPage/blocks/TariffCardBlock/components/Skeleton';
import { usePrepareTariffCardBlock } from 'pages/MainPage/blocks/TariffCardBlock/hooks/usePrepareTariffCardBlock';
import { ETariffBlockType } from 'pages/MainPage/types';

type TTariffCardBlock = {
  blockType: ETariffBlockType;
};

/** Компонент карточки тарифа на главной */
export const TariffCardBlock: React.FC<TTariffCardBlock> = ({ blockType }) => {
  const data = usePrepareTariffCardBlock(blockType);

  if (!data) {
    return <Skeleton />;
  }

  return (
    <ContainerProvider blockTypeString={data.title}>
      {data.title && <Title title={data.title} />}
      {data.card && <TariffCard {...data.card} />}
      {data.chips && <ChipsBlock {...data.chips} />}
    </ContainerProvider>
  );
};
