import { TUser } from '@beef/utils';
import { debounce } from 'lodash';

import store from 'store';
import { getAccountType as _getAccountType } from 'utils/analytics/auth';
import { ymPushParams } from 'utils/ym';

import {
  getButtonAnalyticsConverter,
  getInlineButtonAnalyticsConverter,
  getInputAnalyticsConverter,
} from './analytics/converter';
import { Input } from './components/Input';
import { fetchIsBeelineUser } from './components/Input/api/checkCtn';
import { EUserType } from './components/Input/api/constants';
import { EInputErrorTextConstant } from './components/Input/constants';
import {
  getInputState,
  getInputTexts,
  getInputValue,
  onInputChange,
  setError,
  setIsLoading,
} from './components/Input/store';
import { TInputErrorTexts } from './components/Input/types';
import { getIsPhoneNumber } from './components/Input/utils';
import { ETextConstant } from './constants';
import { getConverter } from './converter';
import { getTexts as _getTexts, getAnimationData, getWidgetPayUrl } from './store';
import { TPaymentWidgetProps, TTexts } from './types';
import { getPropsFromState } from './utils';

const sendAnalytics = debounce((obj: Record<string, unknown>) => {
  if (typeof window === 'undefined') return;

  ymPushParams(obj);
}, 1000);

export const composeRoot = () => {
  const getUserType = (accountNumber: string): EUserType => {
    const isPhoneNumber = getIsPhoneNumber(accountNumber);
    if (isPhoneNumber) return 0;
    return 1;
  };

  const getIsBeelineError = async (accountNumber: string) => {
    const isBeelineUser = await fetchIsBeelineUser({
      accountNumber,
      userType: getUserType(accountNumber),
      baseUrl: getWidgetPayUrl(),
    });

    if (isBeelineUser) return undefined;

    return getInputTexts().beeline || EInputErrorTextConstant.Beeline;
  };

  const getTexts = (): TTexts => {
    const texts = _getTexts();

    return {
      animationData: texts.animationData,
      buttonText: texts.buttonText ?? ETextConstant.ButtonText,
      inlineButtonText: texts.inlineButtonText ?? ETextConstant.InlineButtonText,
      buttonUrl: texts.buttonUrl ?? ETextConstant.ButtonUrl,
      inlineButtonUrl: texts.inlineButtonUrl ?? ETextConstant.InlineButtonUrl,
      inputLabel: texts.inputLabel ?? ETextConstant.InputLabel,
      inputPlaceholder: texts.inputPlaceholder ?? ETextConstant.InputPlaceholder,
      primaryDescription: texts.primaryDescription ?? ETextConstant.PrimaryDescription,
      secondaryDescription: texts.secondaryDescription ?? ETextConstant.SecondaryDescription,
      title: texts.title ?? ETextConstant.Title,
      inputPlaceholderMobile: texts.inputPlaceholderMobile ?? ETextConstant.InputPlaceholderMobile,
    };
  };

  const getInputErrorTexts = (): TInputErrorTexts => {
    const texts = getInputTexts();

    return {
      empty: texts.empty ?? EInputErrorTextConstant.Empty,
      incomplete: texts.incomplete ?? EInputErrorTextConstant.Incomplete,
      beeline: texts.beeline ?? EInputErrorTextConstant.Beeline,
    };
  };

  const getAccountType = (): string => {
    const user = store.getState()?.external?.user as TUser | undefined;

    return _getAccountType(user ?? {});
  };

  const getCommonAnalyticsFields = () => {
    return {
      page_url: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
      account_type: getAccountType(),
      block_title: getTexts().title,
      object_type: 'block',
    };
  };

  const analyticsDependencies = {
    sendAnalytics,
    getCommonAnalyticsFields,
    getTexts,
  };

  const buttonAnalyticsConverter = getButtonAnalyticsConverter(analyticsDependencies);
  const inlineButtonAnalyticsConverter = getInlineButtonAnalyticsConverter(analyticsDependencies);
  const inputAnalyticsConverter = getInputAnalyticsConverter(analyticsDependencies);

  const converter = getConverter({
    buttonAnalyticsConverter,
    inlineButtonAnalyticsConverter,
    inputAnalyticsConverter,
    Input,
    openUrl: (url: string) => {
      if (typeof window === 'undefined') return;
      window.open(url, '_self');
    },
    getTexts,
    getFieldValue: () => getInputValue(getInputState()),
    getAnimationData,
    getInputErrorTexts,
    getIsBeelineError,
    setError,
    setIsLoading,
  });

  const onStateChange = (
    props: Partial<TPaymentWidgetProps>,
    cb: (props: TPaymentWidgetProps) => void,
  ) => {
    return onInputChange((state) => cb(getPropsFromState(props, state)));
  };

  return {
    converter,
    onStateChange,
  };
};
