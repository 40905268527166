import { compose } from '@beef/redux';
import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import {
  selectCurrentService,
  selectIsRenderResultBlock,
  selectServices,
  withProviderServicesCardsMapper,
} from 'pages/MainPage/store/slices/help/selectors';
import { ECard, TITLES_BLOCK } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { RootState } from 'pages/MainPage/types';
import { CellCard, CommonBlock } from 'pages/MainPage/blocks/HelpBlock/blocks/CommonBlock';
import { withAnalyticsCategory } from 'pages/MainPage/blocks/HelpBlock/hocs/withAnalyticsCategory';
import { withAnalyticsCategories } from 'pages/MainPage/blocks/HelpBlock/hocs/withAnalyticsCategories';

export const ServiceBlock = compose<FC<{ children?: ReactNode }>>(
  connect((state: RootState) => ({
    title: TITLES_BLOCK.service,
    shouldRender: !!selectServices(state).length && selectIsRenderResultBlock(state),
  })),
  withProviderServicesCardsMapper,
  withRenderFlag,
  withAnalyticsCategories(ECard.service),
)(CommonBlock);

export const ServiceCard = compose<FC>(
  connect((state: RootState) => ({
    item: selectCurrentService(state),
  })),
  withAnalyticsCategory(ECard.service),
)(CellCard);
