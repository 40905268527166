import React from 'react';
import { Container } from '@carnica/smart-kit';

import { TitleWithNavigation } from 'pages/MainPage/ui';
import { BusinessServicesBlock } from 'pages/MainPage/blocks/BusinessServicesBlock';
import { Section } from 'pages/MainPage/layout/Section';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { BannerCarouselBlock } from 'pages/MainPage/blocks/BannerCarouselBlock';
import { Anchor } from 'pages/MainPage/components/Anchor';
import { TitleWithNavigationProps } from 'pages/MainPage/ui/TitleWithNavigation/types';
import { IBusinessServicesBlock } from 'pages/MainPage/blocks/BusinessServicesBlock/types';
import { BannerCarouselProps } from 'pages/MainPage/blocks/BannerCarouselBlock/types';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';
import { BlockWrapper } from 'pages/MainPage/layout/BlockWrapper';

import { connector } from './connector';

interface IForBusinessProps {
  anchor: string;
  businessCardsBlock: IBusinessServicesBlock;
  inlineBanner: BannerCarouselProps;
  titleNavigation: TitleWithNavigationProps;
}

const _ForBusiness: React.FC<IForBusinessProps> = ({
  titleNavigation,
  businessCardsBlock,
  inlineBanner,
  anchor,
}) => {
  const isMobile = useIsMobileDimension();
  return (
    <>
      <Anchor anchorId={anchor} />
      <Metric
        asChild
        data={titleNavigation.title}
        events={{ commonMetric: ['handleBlockView'] }}
        mode="viewport"
        once
        threshold={0.2}
      >
        <Section
          heading={
            <TitleWithNavigation
              items={titleNavigation.items}
              onNavigationItemClick={(item) =>
                commonMetric.handleBlockNavClick(item.text, titleNavigation.title)
              }
              title={titleNavigation.title}
            />
          }
        >
          {inlineBanner?.slides && (
            <BlockWrapper>
              <BannerCarouselBlock {...inlineBanner} sectionTitle={titleNavigation.title} />
            </BlockWrapper>
          )}
          <Container.Padding left={isMobile ? 8 : 0} right={isMobile ? 8 : 0}>
            <BusinessServicesBlock {...businessCardsBlock} blockTitle={titleNavigation.title} />
          </Container.Padding>
        </Section>
      </Metric>
    </>
  );
};

export const ForBusiness = connector(_ForBusiness);
