import React, { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import { ChipsGroup, Text } from '@carnica/ui-kit';

import { IChips } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { Chips, TChipsProps } from 'pages/MainPage/blocks/TariffCardBlock/components/Chips';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - блок с чипсами */
export const ChipsBlock: FC<IChips> = ({ title, items }) => {
  const chipsItems = useMemo(() => {
    return items
      ?.filter((chips) => chips.isVisible)
      .sort((prev, next) => prev.order - next.order)
      .map((chips, index) => ({
        key: index,
        label: chips.title,
        badge: chips.badge?.text,
        link: chips.link,
        withIcon: chips.withArrow,
      }));
  }, [items]);

  if (!items?.length) {
    return null;
  }

  return (
    <div className={cx('wrapper')}>
      <Text className={cx('text')} variant="body-medium">
        {title}
      </Text>
      <ChipsGroup
        className={cx('block')}
        items={chipsItems}
        layout="wrap"
        renderItem={Chips as TChipsProps}
      />
    </div>
  );
};
