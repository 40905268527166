import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { Link } from '@beef/ui-kit';

import { IChipsItem } from 'pages/MainPage/blocks/EthernetCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - чипса */
export const Chips: FC<IChipsItem> = ({ title, link, badge, onClick }) => {
  return (
    <Link className={cx('chips')} href={link} isNoUnderline onClick={onClick} target="_blank">
      <Text variant="body-small">{title}</Text>
      {/* TODO: брать из carnica */}
      {badge && <div className={cx('chips__badge')}>{badge.text}</div>}
    </Link>
  );
};
