import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ButtonArrow } from 'pages/MainPage/ui/ButtonArrow';
import { CardsList } from 'pages/MainPage/components/Devices/components/CardsList';
import { IDevice } from 'pages/MainPage/components/Devices/types';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { Mobile, ResponsiveContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Devices: React.FC<IDevice> = ({
  title,
  cardImages,
  button,
  isBlankUrlTarget,
  blockTitle,
}) => {
  const isMobile = useIsMobileDimension();

  return (
    <Metric
      data={JSON.stringify({ blockTitle, bannerTitle: title, bannerUrl: button.href })}
      events={{ commonMetric: ['handleBannerView'] }}
      mode="viewport"
      once
      threshold={0.2}
    >
      <Metric
        asChild
        data={JSON.stringify({ blockTitle, bannerTitle: title, bannerUrl: button.href })}
        events={{ commonMetric: ['handleBannerClick'] }}
        mode="click"
      >
        <a
          className={cx('wrapper')}
          href={button.href}
          target={MenuUrlTarget.get(isBlankUrlTarget)}
        >
          <div className={cx('content')}>
            <Text
              align={isMobile ? 'center' : 'left'}
              variant={isMobile ? 'headline-small' : 'display-small'}
            >
              {title}
            </Text>
            <ResponsiveContainer end="desktop" start="tablet">
              <Metric
                asChild
                data={JSON.stringify({
                  blockTitle,
                  bannerTitle: title,
                  bannerUrl: button.href,
                  buttonText: button.text,
                })}
                events={{ commonMetric: ['handleBannerButtonClick'] }}
                mode="click"
              >
                <ButtonArrow arrowFill="secondary" href={button.href}>
                  <Text className={cx('nowrap')} color="primary" variant="body-accent-small">
                    {button.text}
                  </Text>
                </ButtonArrow>
              </Metric>
            </ResponsiveContainer>
          </div>
          <CardsList cardsImages={cardImages} />
          <Mobile>
            <Metric
              asChild
              data={JSON.stringify({
                blockTitle,
                bannerTitle: title,
                bannerUrl: button.href,
                buttonText: button.text,
              })}
              events={{ commonMetric: ['handleBannerButtonClick'] }}
              mode="click"
            >
              <ButtonArrow arrowFill="primary" href={button.href}>
                <Text className={cx('nowrap')} color="secondary" variant="body-accent-small">
                  {button.text}
                </Text>
              </ButtonArrow>
            </Metric>
          </Mobile>
        </a>
      </Metric>
    </Metric>
  );
};
