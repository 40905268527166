const NEW_CHAT_SELECTOR = '[aria-label="Открыть чат"]';
const OLD_CHAT_SELECTOR = '[aria-label="Чат с оператором"]';

export const getChatButtonFromDOM = (): HTMLButtonElement | null =>
  document?.querySelector(NEW_CHAT_SELECTOR) || document?.querySelector(OLD_CHAT_SELECTOR);

export const onOpenChat = (isChat?: boolean) => {
  if (!isChat) {
    const chat = getChatButtonFromDOM();
    chat?.click();
  }
};
