import { getLocalCurrencyStr } from '@beef/utils';

import { CONTENT_TYPE, PRODUCT_TYPE, SuggestionsResponseData } from '../types';
import { ATTRIBUTES_NAME, CARD_TYPE, CATEGORIES, CATEGORY_LIST, UNIT } from './constants';

const getSubtitleCompact = (price: number | string, unit?: string) => {
  const textUnit = unit ? `/${unit}` : '';
  const priceValue = Number(price);
  if (!Number.isNaN(priceValue) && (priceValue || priceValue === 0)) {
    return `${getLocalCurrencyStr(price)}${textUnit}`;
  }

  return '';
};

const preparedPrice = (type: CARD_TYPE, price: number | string) => {
  const unit = type === CARD_TYPE.tariff ? UNIT : '';

  if (type === CARD_TYPE.product) {
    return getLocalCurrencyStr(price);
  }
  if (type === CARD_TYPE.service) {
    const priceValue = Number(price) || 'none';
    return getSubtitleCompact(priceValue, unit);
  }
  if (type === CARD_TYPE.tariff) {
    return getSubtitleCompact(price, unit);
  }
  return '';
};

export const getCompactInfo = (cards: PRODUCT_TYPE[], cardType: CARD_TYPE) =>
  cards
    .filter((card) => {
      if (cardType === CARD_TYPE.product) {
        return card.attributes?.[ATTRIBUTES_NAME.product];
      }
      return card.categories?.[0].id === CATEGORIES[cardType];
    })
    .sort((a, b) => (b?.score || 0) - (a?.score || 0))
    .slice(0, 5)
    .map((element) => {
      const price = preparedPrice(cardType, element.price);

      const imageUrl =
        cardType === CARD_TYPE.product ?
          element.image_url
        : element.attributes?.icon_url?.[0] || element.attributes?.icon_url;

      return {
        href: element.link_url,
        id: element.id,
        image_url: imageUrl,
        subtitle: price,
        title: element.name,
        score: element.score,
        category: cardType,
      };
    });

const getOthers = (articles: CONTENT_TYPE[]) =>
  articles?.slice(0, 5)?.map((article) => ({
    id: article.id,
    href: article.link_url,
    title: article.name,
    category: CARD_TYPE.other,
  }));

// const getTaps = (taps: { relatedSearch: string; tap: string }[]): TapType[] =>
//   taps?.slice(0, 6)?.map((tap, index) => ({
//     key: index,
//     label: tap.tap,
//     relatedSearch: tap.relatedSearch,
//   }));

export const prepareResponseData = (data: SuggestionsResponseData) => ({
  correction: data.correction || '',
  elk: getCompactInfo(data.products, CARD_TYPE.elk),
  tariffs: getCompactInfo(data.products, CARD_TYPE.tariff),
  services: getCompactInfo(data.products, CARD_TYPE.service),
  others: getOthers(data.contents),
});
const getLimit = (categories: number, positions: number) => {
  if (!categories) {
    return 0;
  }
  if (
    (categories === 3 && positions >= 6) ||
    (categories === 4 && positions > 8) ||
    (categories === 5 && positions > 11)
  ) {
    return 3;
  }
  return 4;
};

const prepareLimit = (responseData: any) => {
  let positions = 0;
  const categories = CATEGORY_LIST.reduce((accum: string[], category: string) => {
    const currentPositions = responseData[category]?.length;

    if (currentPositions) {
      accum.push(category);
      positions += currentPositions;
    }

    return accum;
  }, []);

  const countCategories = categories?.length || 0;

  return {
    availableCategories: categories,
    limit: getLimit(countCategories, positions),
  };
};

export const prepareResult = (data: SuggestionsResponseData) => {
  const responseData = prepareResponseData(data);
  const { availableCategories, limit } = prepareLimit(responseData);

  if (!limit) {
    return {
      ...responseData,
      isEmpty: true,
    };
  }

  const categories = availableCategories.reduce((acc, category) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const slicedCategory = responseData[category]?.slice(0, limit);
    return {
      ...acc,
      [category]: slicedCategory,
    };
  }, {});

  return {
    ...responseData,
    ...categories,
    isEmpty: false,
  };
};
