import { createSelector } from '@reduxjs/toolkit';
import { emptyArr, emptyObj } from '@beef/utils';
import { createCurrentSelector } from '@beef/redux';

import { getSearchPageUrl } from 'pages/MainPage/blocks/HelpBlock/ulils/getSearchPageUrl';
import { ECard, IconSrcRobot, STEP } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { getCardKey } from 'pages/MainPage/blocks/HelpBlock/ulils/getKey';
import { selectHelpSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';
import { CardCellType } from 'pages/MainPage/blocks/HelpBlock/types';
import { TYPE_ANALYTICS_RESULT } from 'pages/MainPage/blocks/HelpBlock/metric/constants';

export const selectHelp = (state: RootState) => state.helpSlice || emptyObj;
export const selectHelpCommonContent = createSelector(
  selectHelpSection,
  (help) => help.content || emptyObj,
);

export const selectHelpCommonContentMain = createSelector(
  selectHelpCommonContent,
  (help) => help.main || emptyObj,
);
export const selectIsRenderBlock = createSelector(
  selectHelpCommonContent,
  (help) => help.isEnabled,
);
export const selectAnchorHelpBlock = createSelector(
  selectHelpCommonContent,
  (help) => help.anchor || '',
);
export const selectHelpCommonData = createSelector(
  selectHelpSection,
  (help) => help.data || emptyObj,
);

const SEARCH_URL = '/search/';

export const selectSearchResultsPageUrl = createSelector(
  selectHelpCommonData,
  (data) => data.searchResultsPageUrl || SEARCH_URL,
);
const selectDefaults = createSelector(selectHelpCommonData, (data) => data.defaults || emptyObj);
export const selectBanners = createSelector(selectDefaults, (data) => data.banners || emptyObj);

export const selectRequestUrl = createSelector(
  selectHelpCommonData,
  (data) => data.autocompleteUrl,
);
export const selectError = createSelector(selectHelp, (data) => data.error);
export const selectContentErrorText = createSelector(
  selectDefaults,
  (data) => data.contentErrorText,
);
export const selectContentEmptyText = createSelector(
  selectDefaults,
  (data) => data.contentEmptyText,
);
export const selectIsLoading = createSelector(selectHelp, (data) => data.isLoading);
export const selectTaps = createSelector(
  selectHelpCommonContentMain,
  (data) => data.popularRequests?.map((item: string) => ({ key: item, label: item })),
);

export const selectTitleHelp = createSelector(
  selectHelpCommonContentMain,
  (data) => data.title || '',
);
export const selectPlaceholderHelp = createSelector(
  selectHelpCommonContentMain,
  (data) => data.searchPlaceholder || '',
);
export const selectChatTitleHelp = createSelector(
  selectHelpCommonContentMain,
  (data) => data.chatTitle || '',
);
export const selectChatButtonHelp = createSelector(
  selectHelpCommonContentMain,
  (data) => data.chatButtonText || '',
);

export const selectIconHelpDesktop = createSelector(
  selectHelpCommonContentMain,
  (data) => data.icon?.desktop || IconSrcRobot.desktop,
);

export const selectIsOpenModal = createSelector(selectHelp, (data) => data.isOpen);
export const selectQuery = createSelector(selectHelp, (data) => data.query || '');

const selectAnyQueryData = createSelector(selectHelp, (data) => data.anyQueryData);
export const selectCorrection = createSelector(selectHelp, (data) => data.correction || data.query);

export const selectStep = createSelector(selectHelp, (data) => data.step);

export const selectElk = createSelector(selectAnyQueryData, (data) => data.elk || emptyArr);
export const selectTariffs = createSelector(selectAnyQueryData, (data) => data.tariffs || emptyArr);
export const selectServices = createSelector(
  selectAnyQueryData,
  (data) => data.services || emptyArr,
);
export const selectOther = createSelector(selectAnyQueryData, (data) => data.others || emptyArr);

export const selectIsEmptyResponse = createSelector(selectAnyQueryData, (data) => data.isEmpty);

export const selectIsRenderAllResult = createSelector(
  selectStep,
  selectIsLoading,
  selectError,
  (step, isLoading, { message }) => step === STEP.result && !isLoading && !message,
);
export const selectIsRenderHistory = createSelector(
  selectError,
  selectIsEmptyResponse,
  ({ message }, isEmpty) => !message && !isEmpty,
);
export const selectAllResultUrl = createSelector(
  selectCorrection,
  selectSearchResultsPageUrl,
  (query, url) => getSearchPageUrl({ url, query }),
);

export const selectIsRenderResultBlock = createSelector(selectStep, (step) => step === STEP.result);
export const selectIsRenderRecentlyBlock = createSelector(
  selectStep,
  selectOther,
  (step, list) => step === STEP.recently && !!list?.length,
);

export const {
  selectCurrent: selectCurrentTariff,
  withCurrentProviderMapper: withProviderTariffsCardsMapper,
} = createCurrentSelector(selectTariffs, getCardKey);

export const {
  selectCurrent: selectCurrentElk,
  withCurrentProviderMapper: withProviderElkCardsMapper,
} = createCurrentSelector(selectElk, getCardKey);

export const {
  selectCurrent: selectCurrentOther,
  withCurrentProviderMapper: withProviderOthersCardsMapper,
} = createCurrentSelector(selectOther, getCardKey);

export const {
  selectCurrent: selectCurrentService,
  withCurrentProviderMapper: withProviderServicesCardsMapper,
} = createCurrentSelector(selectServices, getCardKey);

export const getListByCategory = (state: RootState, category: ECard) => {
  const currentList = selectAnyQueryData(state)?.[TYPE_ANALYTICS_RESULT[category]] || [];
  return currentList.map((item: CardCellType) => item.title);
};
