import React from 'react';

import {
  ElkBlock,
  ElkCard,
  Empty,
  Error,
  FooterButtonRC,
  OtherBlock,
  OtherCard,
  RecommendationBlock,
  RecommendationCard,
  ScrollableWrapper,
  Section,
  ServiceBlock,
  ServiceCard,
  SkeletonLayoutRC,
  TariffBlock,
  TariffCard,
} from './blocks';

export const SearchResult: React.FC = () => {
  return (
    <SkeletonLayoutRC>
      <ScrollableWrapper>
        <RecommendationBlock>
          <RecommendationCard />
        </RecommendationBlock>
        <OtherBlock>
          <OtherCard />
        </OtherBlock>
        <ElkBlock>
          <ElkCard />
        </ElkBlock>
        <ServiceBlock>
          <ServiceCard />
        </ServiceBlock>
        <TariffBlock>
          <TariffCard />
        </TariffBlock>
        <Error />
        <Empty />
      </ScrollableWrapper>
      <Section isBottomFixed>
        <FooterButtonRC />
      </Section>
    </SkeletonLayoutRC>
  );
};
