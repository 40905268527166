import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsMobileDimension } from '@beef/ui-kit';
import { Button } from '@carnica/ui-kit';
import { IconArrowsLeft, IconArrowsRight } from '@carnica/graphics';
import SwiperClass from 'swiper/types/swiper-class';

import { ServiceCard } from 'pages/MainPage/ui/ServiceCard';

import styles from './styles.pcss';
import { CardsCarouselProps } from './types';

const cx = classNames.bind(styles);

export const CardsCarousel: React.FC<{
  onCardClick?: (title: string) => void;
  slides: CardsCarouselProps[];
}> = ({ slides, onCardClick }) => {
  const isMobile = useIsMobileDimension();
  const [SwiperInstance, setSwiperInstance] = useState<SwiperClass>(null);
  const [progress, setProgress] = useState<number>(0);

  const setInstance = (swiper: SwiperClass) => {
    setSwiperInstance(swiper);
    setProgress(swiper.progress);
  };

  const nextSlide = () => SwiperInstance?.slideNext();
  const prevSlide = () => SwiperInstance?.slidePrev();

  useEffect(() => {
    if (SwiperInstance) {
      SwiperInstance?.updateSlides();
      SwiperInstance?.slideTo(0);
    }
  }, [slides]);

  return (
    <div className={cx('carousel-container')}>
      <div className={cx('carousel-arrow', 'arrow-prev')}>
        {progress > 0 && (
          <Button
            className={cx('arrow-button', 'arrow-prev__button')}
            iconLeft={IconArrowsLeft}
            onClick={prevSlide}
            onlyIcon
            view="secondary"
          />
        )}
      </div>
      <Swiper
        className={cx('carousel-swiper')}
        onSlideChange={setInstance}
        onSwiper={setInstance}
        slidesPerView="auto"
        spaceBetween={isMobile ? 16 : 24}
      >
        {slides?.map((item) => (
          <SwiperSlide className={cx('carousel-slide')} key={item.id}>
            <ServiceCard
              hideChevron
              href={item?.href}
              icon={item.iconUrl}
              isBlankUrlTarget={item.isBlankUrlTarget}
              label={item.label}
              mode="light"
              onClick={onCardClick}
              title={`${item.title}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={cx('carousel-arrow', 'arrow-next')}>
        {progress !== 1 && (
          <Button
            className={cx('arrow-button', 'arrow-next__button')}
            iconLeft={IconArrowsRight}
            onClick={nextSlide}
            onlyIcon
            view="secondary"
          />
        )}
      </div>
    </div>
  );
};
