import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/* СОздаем массив из 6 элементов */
const chipsMock = [...Array(6).keys()];

/** Скелетон карточк тарифа. Применяется во время отжидания ответа сервера */
export const Skeleton = () => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('component')}>
        <div className={cx('title')} />
        <div className={cx('card')}>
          <div className={cx('card__title')} />
          <div className={cx('card__subtitle')} />
          <div className={cx('card__info1')} />
          <div className={cx('card__info2')} />
          <div className={cx('card__info3')} />
          <div className={cx('card__option')}>
            <div className={cx('card__option_icon')} />
            <div>
              <div className={cx('card__option_title')} />
              <div className={cx('card__option_label')} />
            </div>
          </div>
          <div className={cx('card__label')} />
        </div>
        <div className={cx('label')} />
        <div className={cx('box')}>
          {chipsMock.map((i: number) => (
            <div className={cx('chips')} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};
