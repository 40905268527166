import { TInputProps } from '../components/Input/types';
import { TButtonProps, TTexts } from '../types';

export const getButtonAnalyticsConverter =
  ({
    sendAnalytics,
    getTexts,
    getCommonAnalyticsFields,
  }: {
    getCommonAnalyticsFields: () => Record<string, string>;
    getTexts: () => TTexts;
    sendAnalytics: (obj: Record<string, unknown>) => void;
  }) =>
  (props: TButtonProps): TButtonProps => ({
    ...props,
    onClick: () => {
      const texts = getTexts();

      const obj = {
        main: {
          ...getCommonAnalyticsFields(),
          action: 'click',
          item_type: 'button',
          button_text: texts.buttonText,
        },
      };

      sendAnalytics(obj);

      props.onClick();
    },
  });

export const getInlineButtonAnalyticsConverter =
  ({
    sendAnalytics,
    getTexts,
    getCommonAnalyticsFields,
  }: {
    getCommonAnalyticsFields: () => Record<string, string>;
    getTexts: () => TTexts;
    sendAnalytics: (obj: Record<string, unknown>) => void;
  }) =>
  (props: TButtonProps): TButtonProps => ({
    ...props,
    onClick: () => {
      const texts = getTexts();

      const obj = {
        main: {
          ...getCommonAnalyticsFields(),
          action: 'click',
          item_type: 'link',
          link_text: texts.inlineButtonText,
          object_type: 'block',
          block_title: texts.primaryDescription,
        },
      };

      sendAnalytics(obj);

      props.onClick();
    },
  });

export const getInputAnalyticsConverter =
  ({
    sendAnalytics,
    getTexts,
    getCommonAnalyticsFields,
  }: {
    getCommonAnalyticsFields: () => Record<string, string>;
    getTexts: () => TTexts;
    sendAnalytics: (obj: Record<string, unknown>) => void;
  }) =>
  (props: TInputProps): TInputProps => ({
    ...props,
    onClick: () => {
      const texts = getTexts();

      const obj = {
        main: {
          ...getCommonAnalyticsFields(),
          action: 'click',
          item_type: 'field',
          field_name: texts.inputPlaceholder,
        },
      };

      sendAnalytics(obj);

      return props.onClick();
    },
    onChange: async (value) => {
      const texts = getTexts();

      const obj = {
        main: {
          ...getCommonAnalyticsFields(),
          action: 'fill_in',
          item_type: 'field',
          field_name: texts.inputPlaceholder,
        },
      };

      sendAnalytics(obj);

      return props.onChange(value);
    },
  });
