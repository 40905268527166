import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import type { RootState } from 'pages/MainPage/types';
import { selectIconHelpDesktop } from 'pages/MainPage/store/slices/help/selectors';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type Props = { children: ReactNode; icon: string };

const _Block: FC<Props> = ({ children, icon }) => {
  const isMobile = useIsMobileDimension();
  return (
    <Metric
      asChild
      events={{ helpBlockMetric: ['handleViewHelpBlock'] }}
      mode="viewport"
      once
      threshold={0.5}
    >
      <div className={cx('help-block')}>
        {children}
        {!isMobile && <img alt="" className={cx('help-block__icon')} src={icon} />}
      </div>
    </Metric>
  );
};

const mapStateToProps = (state: RootState) => ({
  icon: selectIconHelpDesktop(state),
});

export const Block = connect(mapStateToProps)(_Block);
