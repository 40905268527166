import { PayloadAction, configureStore, createSlice } from '@reduxjs/toolkit';

import { TPaymentSlice as TPaymentState } from './types';
import { TInputState } from '../../components/Input/types';
import { TContent } from '../../types';

const initialState: TPaymentState = {
  input: {},
  content: {},
};

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    setInput: (state, action: PayloadAction<TInputState>) => {
      state.input = action.payload;
    },
    setContent: (state, action: PayloadAction<TContent>) => {
      state.content = action.payload;
    },
  },
});

export const store = configureStore({
  reducer: paymentSlice.reducer,
});
