import { createSlice } from '@reduxjs/toolkit';

import { STEP } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { SearchStoreType } from 'pages/MainPage/blocks/HelpBlock/types';

import { ActionSearch } from './actions';

type ErrorFetchSearchType = { status: number; statusText: string };

const initialState: SearchStoreType = {
  isLoading: false,
  error: { code: null, message: '' },
  query: '',
  correction: '',
  anyQueryData: {},
  step: STEP.recently,
  isOpen: false,
  isWebView: false,
};

const helpSearchSlice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ActionSearch.fetchSearchData.pending, (state) => {
        state.error = { code: null, message: '' };
        state.isLoading = true;
      })
      .addCase(ActionSearch.fetchSearchData.fulfilled, (state, action) => {
        const { correction, ...resultBlocks } = action.payload;
        state.correction = correction;
        state.anyQueryData = resultBlocks;
        state.isLoading = false;
      })
      .addCase(ActionSearch.fetchSearchData.rejected, (state, action) => {
        state.anyQueryData = {};
        const { status, statusText } = action?.payload as ErrorFetchSearchType;

        state.error = {
          code: status || null,
          message: statusText || action.error?.message,
        };
        state.isLoading = false;
      })
      .addCase(ActionSearch.setQuery, (state, { payload }) => {
        state.query = payload;
      })
      .addCase(ActionSearch.setStep, (state, { payload }) => {
        state.step = payload;
      })
      .addCase(ActionSearch.toggleOpenModal, (state, { payload }) => {
        state.isOpen = payload;
      });
  },
});

export const helpSlice = helpSearchSlice.reducer;
