import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { Header } from 'pages/MainPage/blocks/TariffCardBlock/components/Header';
import { Footer } from 'pages/MainPage/blocks/TariffCardBlock/components/Footer';
import { CardDescription } from 'pages/MainPage/blocks/TariffCardBlock/components/CardDescription';
import { Options } from 'pages/MainPage/blocks/TariffCardBlock/components/Options';
import { Gifts } from 'pages/MainPage/blocks/TariffCardBlock/components/Gifts';
import { usePrepareTTariffCard } from 'pages/MainPage/blocks/TariffCardBlock/components/TariffCard/hooks/usePrepareTariffCard';
import { ConnectButton } from 'pages/MainPage/blocks/TariffCardBlock/components/ConnectButton';
import { CardContainer } from 'pages/MainPage/blocks/TariffCardBlock/components/CardContainer';
import { ITariffCard } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент карточки тарифа */
export const TariffCard: FC<ITariffCard> = ({
  bgImage,
  cardTitle,
  options,
  gifts,
  tagLabel,
  presetText,
  button,
  tariffUrl,
  priceText,
}) => {
  const { label, style, metricData } = usePrepareTTariffCard({ cardTitle, bgImage });

  return (
    <CardContainer>
      {label && <CardDescription label={label} />}
      <div className={cx('container')}>
        <Metric data={metricData} events={{ tariffCardMetric: ['handleCardClick'] }} mode="click">
          <a
            className={cx('content', !label && 'content--no-offset')}
            href={tariffUrl}
            rel="noopener noreferrer"
            style={style}
          >
            <Header {...{ tagLabel, presetText }} />
            <Options {...options} />
            {!!gifts?.length && <Gifts gifts={gifts} />}
            <Footer priceText={priceText} />
          </a>
        </Metric>
        <ConnectButton {...button} />
      </div>
    </CardContainer>
  );
};
