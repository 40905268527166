import React, { FC } from 'react';
import { AvatarGroup, Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { IOptions } from 'pages/MainPage/blocks/TariffCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент с опциями тарифа (набор иконок и заголовок) */
export const Options: FC<IOptions> = ({ title, icons }) => (
  <div className={cx('options')}>
    {title && (
      <Text color="secondary-invert" size="xs">
        {title}
      </Text>
    )}
    <AvatarGroup className={cx('options__group')} items={icons} size="s" visibleCount={10} />
  </div>
);
