const getExternal = (state) => state.external;

export const getUserData = (state) => getExternal(state)?.user;

export const getUserInfo = (state) => {
  const userData = getUserData(state) || {};
  const isMobileUser = !('login' in userData) || userData?.isConvergent || userData?.isMobile;
  const fieldKey = isMobileUser ? 'ctn' : 'fttb';

  return {
    type: isMobileUser ? 0 : 1,
    [fieldKey]: userData?.ctn,
    isAuth: 'login' in userData,
  };
};
