import React from 'react';
import classNames from 'classnames/bind';
import { SearchField } from '@carnica/ui-kit';

import { useInputQuery } from './hooks/useInputQuery';
import styles from './styles.pcss';
import { InputType } from './types';

const cx = classNames.bind(styles);

export const Input: React.FC<InputType> = ({
  onSearch,
  onChange,
  onFocus,
  isOpen,
  placeholder,
}) => {
  const { query, ref, handleChange, handleClear, handleKeyDown } = useInputQuery({
    onSearch,
    onFocus,
    onChange,
    isOpenModal: isOpen,
  });

  return (
    <SearchField
      autoFocus
      className={cx('input')}
      inputRef={ref}
      onChange={handleChange}
      onClear={handleClear}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={query}
    />
  );
};
