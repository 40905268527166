import { getIsPhoneNumber } from './components/Input/utils';
import { TPaymentWidgetBlockDependencies, TPaymentWidgetProps } from './types';
import { DEFAULT_PROPS, validateInput } from './utils';
import { findFirst } from './utils.common';

// Зависимости нужны, чтобы отделить детали от самой логики.
// Позволит легко написать тесты (не успел, так как не было времени)
export const getConverter =
  ({
    openUrl,
    Input,
    buttonAnalyticsConverter,
    inputAnalyticsConverter,
    inlineButtonAnalyticsConverter,
    getTexts,
    getFieldValue,
    getAnimationData,
    setIsLoading,
    getIsBeelineError,
    getInputErrorTexts,
    setError,
  }: TPaymentWidgetBlockDependencies) =>
  (props: Partial<TPaymentWidgetProps>): TPaymentWidgetProps => {
    const texts = getTexts();

    const value = getFieldValue();
    const param = [findFirst([getIsPhoneNumber(value) && 'ctn', 'fttb']), value].join('=');

    const validate = async (_value: string): Promise<string | undefined> => {
      const validationError = validateInput(_value, getInputErrorTexts());

      if (validationError) return validationError;

      setIsLoading(true);
      const error = await getIsBeelineError(_value);
      setIsLoading(false);

      if (error) return error;

      return undefined;
    };

    return {
      ...DEFAULT_PROPS,
      ...props,
      animationContainerProps: {
        ...DEFAULT_PROPS.animationContainerProps,
        ...props.animationContainerProps,
        animationData: getAnimationData(),
      },
      textProps: {
        ...DEFAULT_PROPS.textProps,
        children: texts.title,
      },
      buttonProps: buttonAnalyticsConverter({
        ...DEFAULT_PROPS.buttonProps,
        ...props.buttonProps,
        children: texts.buttonText,
        onClick: async () => {
          const error = await validate(props.inputProps?.value ?? '');

          if (error) {
            setError(error);
            return;
          }

          openUrl(`${texts.buttonUrl}?${param}`);
        },
      }),
      inputProps: inputAnalyticsConverter({
        ...DEFAULT_PROPS.inputProps,
        ...props.inputProps,
        Component: Input,
        placeholder:
          props.inputProps?.isMobile ? texts.inputPlaceholderMobile : texts.inputPlaceholder,
        label: texts.inputLabel,
      }),
      text: {
        ...DEFAULT_PROPS.text,
        primary: texts.primaryDescription,
        secondary: texts.secondaryDescription,
      },
      inlineButtonProps: inlineButtonAnalyticsConverter({
        ...DEFAULT_PROPS.inlineButtonProps,
        ...props.inlineButtonProps,
        children: texts.inlineButtonText,
        onClick: () => {
          openUrl(texts.inlineButtonUrl);
        },
      }),
    };
  };
