import { RefObject, useEffect, useMemo, useState } from 'react';

export const useCardHover = (titleRef: RefObject<HTMLElement>, isMobile: boolean) => {
  const [hover, setHover] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  useEffect(() => {
    if (titleRef.current) {
      const titleRect = titleRef.current.getBoundingClientRect();
      setTitleHeight(titleRect.height);
    }

    if (isMobile) {
      setHover(true);
    }
  }, [isMobile, titleRef]);

  const translateYValue = useMemo(() => {
    const CARD_HEIGHT = '180px';
    const PADDING = '20px';

    if (isMobile) {
      return 'none';
    }
    if (hover) {
      return `translateY(${PADDING})`;
    }
    return `translateY(calc(${CARD_HEIGHT} - ${titleHeight}px - ${PADDING}))`;
  }, [isMobile, hover, titleHeight]);

  return { hover, setHover, translateYValue };
};
