import React, { FC } from 'react';
import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import { RootState } from 'pages/MainPage/types';
import {
  selectIsOpenModal,
  selectIsRenderResultBlock,
} from 'pages/MainPage/store/slices/help/selectors';
import { SearchResult } from 'pages/MainPage/blocks/HelpBlock/SearchResult';
import { getPortalSearch } from 'pages/MainPage/blocks/HelpBlock/ulils/getPortalSearch';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
const _ModalSearch: React.FC<{ className?: string; isResult: boolean }> = ({
  className,
  isResult,
}) => {
  // Благодаря withRenderFlag, portal гарантированно при рендере будет HTMLElement
  const portal = getPortalSearch() as HTMLElement;

  return createPortal(
    <Metric
      asChild
      events={{ helpBlockMetric: ['handleViewHelpModal'] }}
      mode="viewport"
      once
      threshold={0.2}
    >
      <div className={cx('modal', { 'modal--result': isResult }, className)} data-portal="portal">
        <SearchResult />
      </div>
    </Metric>,
    portal,
  );
};

const mapStateToProps = (data: RootState) => ({
  isResult: selectIsRenderResultBlock(data),
  shouldRender: !!getPortalSearch() && selectIsOpenModal(data),
});
export const ModalSearch = compose<FC>(connect(mapStateToProps), withRenderFlag)(_ModalSearch);
