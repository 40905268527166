export const unformatValue = (value: string | undefined) => {
  if (!value) return '';

  return value.replace('+7', '').replace(/\D/g, '').slice(0, 10);
};

/**
 * '9031112233', '+7 ___ ___ __ __' => "+7 903 111 22 33"
   '903', '+7 ___ ___ __ __' => "+7 903"
   '224', '_ + _ = _' => "2 + 2 = 4"
 */
export const maskValue = (value: string, mask: string) => {
  const [result] = Array.from(mask).reduce(
    ([_mask, _value], v, i) => {
      if (v !== '_') return [_mask, _value] as [string, string];
      if (!_value) return [_mask.slice(0, i), _value] as [string, string];
      const nextArray = [...Array.from(_mask)];
      nextArray.splice(i, 1, _value[0] ?? '');
      const next = [nextArray.join(''), _value.slice(1)] as [string, string];
      return next as [string, string];
    },
    [mask, value] as [string, string],
  );

  return result.replace(/_/g, '').trimEnd();
};

export const getIsComplete = (value: string) => {
  return unformatValue(value).length >= 10;
};

const COUNTRY_CODE_DIGITS = [7, 8];

export const getIsPhoneNumber = (value: string) => {
  const _value = value.replace(/\D/g, '');
  return [6, 9, ...COUNTRY_CODE_DIGITS] //
    .map(String)
    .includes(_value[0]);
};

export const formatValue = (
  value: string | undefined,
  phoneMask: string = '+7 ___ ___ ____',
  nonPhoneMask: string = '___ ___ ____',
) => {
  if (!value) return '';
  const _value = value.replace(/\D/g, '');
  const isPhoneNumber = getIsPhoneNumber(_value);
  if (!isPhoneNumber) return maskValue(_value, nonPhoneMask);
  const _phone = _value.replace(new RegExp(`^[${COUNTRY_CODE_DIGITS.toString()}]`), '');
  return maskValue(_phone, phoneMask);
};
