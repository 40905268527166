export const getIsNonDevEnvironment = () => {
  if (typeof process === 'undefined') return false;
  const env = process?.env?.NODE_ENV;

  if (!env) return false;

  return env !== 'development';
};

export function findFirst<T>(arr: T[]) {
  return arr.find(Boolean) as Exclude<T, boolean> | undefined;
}
