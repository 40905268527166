import React from 'react';
import classNames from 'classnames/bind';

import { DEFAULT_ICONS } from './constants';
import { CARD_TYPE } from '../../ulils/constants';
import { ImageCellType } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ImageCell: React.FC<ImageCellType> = ({ src, type, isSmall }) => {
  if (type !== CARD_TYPE.product || (!src && type === CARD_TYPE.product)) {
    const Icon = DEFAULT_ICONS[type];
    return <Icon className={cx('icon-default', { 'icon-default__size-m': !isSmall })} />;
  }

  return (
    <div aria-hidden="true" className={cx('icon')}>
      <img alt="изображение результата" src={src} />
    </div>
  );
};
