import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { IconArrowsRight } from '@carnica/graphics';
import { Button } from '@carnica/ui-kit';

import { RESULT_TEXT } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type PropsType = { href: string };

export const FooterButton: FC<PropsType> = ({ href }) => {
  return (
    <nav className={cx('footer-nav__wrapper')}>
      <Metric asChild events={{ helpBlockMetric: ['handleButtonAllResultClick'] }} mode="click">
        <Button
          className={cx('footer-nav__button')}
          href={href}
          iconRight={IconArrowsRight}
          iconRightView="background"
          mode="inline"
          tagName="a"
        >
          {RESULT_TEXT}
        </Button>
      </Metric>
    </nav>
  );
};
