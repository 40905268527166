import { compose } from '@beef/redux';
import { FC } from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { RootState } from 'pages/MainPage/types';
import {
  selectAllResultUrl,
  selectIsRenderAllResult,
} from 'pages/MainPage/store/slices/help/selectors';

import { FooterButton } from './FooterButton';

const mapStateToProps = (state: RootState) => ({
  href: selectAllResultUrl(state),
  shouldRender: selectIsRenderAllResult(state),
});

export const FooterButtonRC = compose<FC>(connect(mapStateToProps), withRenderFlag)(FooterButton);
