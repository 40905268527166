import { defineUrlIcon } from '@carnica/graphics';
import { TabBarDefaultItem } from '@carnica/ui-kit/components/TabBar/types';
import { MenuDefaultItem } from '@carnica/ui-kit/components/Menu/types';
import { RefObject } from 'react';

import { BreakpointEnum } from 'pages/MainPage/types';

export const defineIconFromUrl = (url: string) => {
  return defineUrlIcon({
    url,
    ratio: 1,
  });
};

interface TabBarData {
  breakpoint: BreakpointEnum;
  items: TabBarDefaultItem[];
  menu: MenuDefaultItem[];
  sectionRef: RefObject<HTMLDivElement>;
}

export const convertTabBarBlock = (data: TabBarData) => {
  const parsedMenuIcon = data.menu.map((item) => {
    return {
      ...item,
      icon: defineIconFromUrl(item.icon),
    };
  });
  const anchorsIds = data.items.map((item) => item.key.split('#').join(''));
  const isMobile = data.breakpoint < BreakpointEnum.TABLET_1024;
  return {
    ...data,
    anchorsIds,
    isMobile,
    menu: parsedMenuIcon,
  };
};

export const handleItemClick = (key: string) => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const sectionElement = document.getElementById(key);
  if (sectionElement) {
    const topOffset = sectionElement.getBoundingClientRect().top + window.scrollY - 100;
    window.scrollTo({ top: topOffset, behavior: 'smooth' });
  }

  return undefined;
};
