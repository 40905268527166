import React, { FC } from 'react';
import classNames from 'classnames/bind';
import times from 'lodash/times';

import styles from './styles.pcss';
import { Skeleton } from './SkeletonBasic';

const cx = classNames.bind(styles);

export const SkeletonTaps: FC = () => (
  <div className={cx('taps')}>
    <ul className={cx('taps__list')}>
      {times(5, (index) => (
        <li className={cx('taps__tap')} key={index}>
          <Skeleton className={cx('taps__tap', 'figure')} />
        </li>
      ))}
    </ul>
  </div>
);
