type TUser = Partial<{
  isConvergent: boolean;
  isMobile: boolean;
}>;

export const LOGIN_TYPES = {
  mobile: 'mobile',
  mobileConvergence: 'mobileConvergence',
  login: 'login',
  b2b: 'b2b',
  unknown: 'unknown',
};

export const LOGIN_TYPES_ANALYTICS = {
  mobile: 'mobile', // Мобильная связь
  fttb: 'fttb', // ШПД
  fmc: 'fmc', // Конвергент
  none: 'none', // Если пользователь не авторизован
};

export const getAccountType = (user: TUser) => {
  if (!user) return LOGIN_TYPES_ANALYTICS.none;
  const { isMobile, isConvergent } = user;
  if (isConvergent) {
    return LOGIN_TYPES_ANALYTICS.fmc;
  }
  if (isMobile) {
    return LOGIN_TYPES_ANALYTICS.mobile;
  }
  return LOGIN_TYPES_ANALYTICS.fttb;
};
