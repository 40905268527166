export const getSearchPageUrl = ({
  url,
  query,
  category,
}: {
  category?: string;
  query?: string;
  url: string;
}) => {
  let params = '';
  if (query) {
    params += `term=${encodeURIComponent(query.trim())}`;
  }
  if (category) {
    params += `&categories=${category}`;
  }
  return `${url}?${params}`;
};
