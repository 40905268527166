import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface BlockWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const BlockWrapper: React.FC<BlockWrapperProps> = ({ children, className }) => {
  return <div className={cx('block-wrapper', className)}>{children}</div>;
};
