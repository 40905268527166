import { TInputProps } from './types';
import { formatValue, unformatValue } from './utils';

export const getConverter =
  ({
    setInputValue,
    setError,
    getInputValue,
    getIsLoading,
  }: {
    getInputValue: () => string;
    getIsLoading: () => boolean;
    getTexts: () => {
      empty: string;
      incomplete: string;
    };
    setError: (error: string) => void;
    setInputValue: (phoneNumber: string | undefined) => void;
    setIsLoading: (isLoading: boolean) => void;
  }) =>
  (props: TInputProps) => {
    return {
      ...props,
      disabled: getIsLoading(),
      onChange: async (value) => {
        props.onChange(value);

        const result = unformatValue(value);

        setInputValue(result);
        setError('');
      },
      onBlur: async () => {},
      value: formatValue(getInputValue() ?? props.value),
    } as TInputProps;
  };
