import { connect } from 'react-redux';

import { selectForDevicesSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForDevicesSection(state).titleNavigation,
    cardsBlock: selectForDevicesSection(state).cardsBlock,
    devices: selectForDevicesSection(state).devices,
    inlineBanner: selectForDevicesSection(state).inlineBanner,
    anchor: selectForDevicesSection(state).anchor,
  };
};

export const connector = connect(mapStateToProps);
