import { useState } from 'react';
import { setCartData } from '@beef/layout-kit/actions';
import { CART_URL, formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';

import globalStore from 'store';
import { IBasketPayload } from 'pages/MainPage/blocks/TariffCardBlock/types';

interface IUseConnectBasket {
  basketPayload: IBasketPayload;
}

/** Хук события перехода в корзину */
export const useConnectBasket = ({ basketPayload }: IUseConnectBasket) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConnect = async () => {
    setIsLoading(true);
    const { cartApi } = globalStore.getState().external.cartData;
    // @ts-expect-error - кто-то ввел для formatOrderData обязательный параметр additionalCtnOptions: Object
    const requestData = formatOrderData(basketPayload);

    try {
      const { data } = await cartApi.post(requestData.apiEndpoint, requestData.body);
      if (data.id) {
        handleSuccessRequest({
          cartId: data.id,
          items: data.items,
        });

        globalStore.dispatch(setCartData(data));

        if (typeof window !== 'undefined') {
          window.location.href = CART_URL;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onConnect,
  };
};
