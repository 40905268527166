import { useCallback, useMemo, useRef } from 'react';
import { debounce } from '@beef/utils';
import { EKeyCodes } from '@carnica/utils/types';

import { useAppSelector } from 'pages/MainPage/store/common';
import {
  selectAnchorHelpBlock,
  selectCorrection,
  selectQuery,
  selectSearchResultsPageUrl,
} from 'pages/MainPage/store/slices/help/selectors';
import { sanitizeSearch } from 'pages/MainPage/utils/utils';
import { scrollToBlock } from 'pages/MainPage/blocks/HelpBlock/ulils/scroll';
import { decorateHandler } from 'pages/MainPage/metric';

import { getSearchPageUrl } from '../../../ulils/getSearchPageUrl';
import { InputType } from '../types';

export const useInputQuery = ({ onSearch, onChange, onFocus, isOpenModal }: InputType) => {
  const query = useAppSelector(selectQuery);
  const correction = useAppSelector(selectCorrection);
  const urlSearchResultsPage = useAppSelector(selectSearchResultsPageUrl);
  const anchorId = useAppSelector(selectAnchorHelpBlock);

  const ref = useRef<HTMLInputElement>(null);

  const onSearchDebounce = useMemo(
    () =>
      debounce(500, (args: string) => {
        decorateHandler(() => onSearch?.(args), { helpBlockMetric: ['handleFillInSearch'] })();
      }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChange = useCallback(
    (value: string = '') => {
      const pureValue = sanitizeSearch(value);
      const isError = (!pureValue && value) || pureValue === query;
      onChange?.(pureValue);

      if (!isError) {
        onSearchDebounce(pureValue);
      }
    },
    [query], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClear = useCallback(() => {
    onChange?.('');
    onFocus?.('');

    ref.current?.focus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = useCallback(
    (e: any) => {
      const valueSearch = correction || query;
      if (valueSearch && e.key === EKeyCodes.Enter) {
        window.location.assign(getSearchPageUrl({ url: urlSearchResultsPage, query: valueSearch }));
      }
    },
    [query, correction], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = useCallback(() => {
    scrollToBlock(anchorId);

    if (!isOpenModal) {
      onFocus?.(query);
    }
  }, [query, isOpenModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => ({ query, ref, handleChange, handleClear, handleKeyDown, handleClick }),
    [query, ref, handleChange, handleClear, handleKeyDown, handleClick],
  );
};
