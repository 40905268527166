import { connect } from 'react-redux';

import { selectServiceSectionBlock } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    ...selectServiceSectionBlock(state),
  };
};

export const connector = connect(mapStateToProps);
