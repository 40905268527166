import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, style, className }) => (
  <div className={cx('container', { className })} style={style}>
    {children}
  </div>
);
