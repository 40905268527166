import React from 'react';
import classNames from 'classnames/bind';

import { MainCarouselBlock } from 'pages/MainPage/blocks/MainCarouselBlock';
import { MainCarouselProps } from 'pages/MainPage/blocks/MainCarouselBlock/types';

import { connector } from './connector';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface MainCarouselSectionProps extends MainCarouselProps {
  innerRef?: React.Ref<HTMLDivElement>;
}

const _MainCarouselSection: React.FC<MainCarouselSectionProps> = ({
  slides,
  innerRef,
  duration,
}) => {
  return (
    <div className={cx('sections')} ref={innerRef}>
      <MainCarouselBlock duration={duration} slides={slides} />
    </div>
  );
};

export const MainCarouselSection = connector(_MainCarouselSection);
