import { useEffect, useState } from 'react';
import axios from 'axios';

import { fetchData } from 'pages/MainPage/blocks/TariffCardBlock/api';
import { ITariffCardBlockProps } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { ETariffBlockType } from 'pages/MainPage/types';

/** Хук получения данных тарифов */
export const usePrepareTariffCardBlock = (blockType: ETariffBlockType) => {
  const [result, setResult] = useState<ITariffCardBlockProps | null>(null);
  const params = { blockType };
  useEffect(() => {
    const { token: cancelToken, cancel } = axios.CancelToken.source();
    fetchData({ params, cancelToken })
      .then(({ data }) => {
        setResult(data ?? null);
      })
      .catch(() => setResult(null));
    return cancel;
  }, []);

  return result;
};
