import React from 'react';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/utils';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type AnchorTypes = {
  additionalClass?: string;
  anchorId: string;
};

const AnchorComponent: React.FC<AnchorTypes> = ({ anchorId, additionalClass }) => (
  <span className={cx('anchor', additionalClass)} id={anchorId} />
);

export const Anchor = compose<React.FC<AnchorTypes>>(
  React.memo,
  withRenderFlag({
    shouldRenderFn: (props: Partial<AnchorTypes>) => Boolean(props?.anchorId),
  }),
)(AnchorComponent);
