import { ISection, SectionType } from 'pages/MainPage/types';

export const MenuUrlTarget: Map<boolean, string> = new Map([
  [false, '_self'],
  [true, '_blank'],
]);

// Позволяет писать буквы и цифры, очищает от HTML тегов.
export const sanitizeSearch = (string: string, replacement = '') => {
  const regex = /<([^>]+)>|[/<>|?!]/gi;
  return string.replace(regex, replacement);
};

export const reduceSections = (sections: ISection[]) => {
  return sections.reduce(
    (acc, section) => {
      acc[section.section as SectionType] = section;
      return acc;
    },
    {} as Partial<Record<SectionType, ISection>>,
  );
};
