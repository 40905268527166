import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { connect } from 'react-redux';

import { BreakpointEnum, RootState } from 'pages/MainPage/types';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';
import { useCardHover } from './utils';
import { CardProps } from '../../types';

const cx = classNames.bind(styles);

export const _Card: React.FC<CardProps> = ({
  title,
  backgroundSrcMobile,
  subtitle,
  iconSrc,
  backgroundSrc,
  href,
  breakpoint,
  isBlankUrlTarget,
  ...otherProps
}) => {
  const isMobile = breakpoint <= BreakpointEnum.TABLET_1024;
  const titleRef = useRef(null);
  const { hover, setHover, translateYValue } = useCardHover(titleRef, isMobile);

  return (
    <a
      className={cx('card')}
      href={href}
      onMouseEnter={() => !isMobile && setHover(true)}
      onMouseLeave={() => !isMobile && setHover(false)}
      target={MenuUrlTarget.get(isBlankUrlTarget)}
      {...otherProps}
    >
      <img alt="изображение карточки" className={cx('icon', { hover })} src={iconSrc} />
      <div
        className={cx('content', { hover })}
        style={{
          transform: translateYValue,
        }}
      >
        <Text ref={titleRef} size="s">
          {title}
        </Text>
        <Text className={cx('subtitle', { hover })} color="secondary" variant="body-small">
          {subtitle}
        </Text>
      </div>
      <img
        alt=""
        className={cx('background-image', { hover })}
        src={isMobile ? backgroundSrcMobile : backgroundSrc}
      />
    </a>
  );
};

const mapStateToProps = (state: RootState) => ({
  breakpoint: state.breakpoint,
});

export const Card = connect(mapStateToProps)(_Card);
