import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';
import { INavigationProps } from '../../types';

const cx = classNames.bind(styles);

export const Navigation: React.FC<INavigationProps> = ({ items, onClick }) => {
  return (
    <nav>
      <ul className={cx('navigation')}>
        {items.map((item) => (
          <li key={item.text}>
            <Text
              className={cx('navigation__link')}
              color="secondary"
              href={item.href}
              onClick={() => onClick?.(item)}
              tagName="a"
              target={MenuUrlTarget.get(item.isBlankUrlTarget)}
              variant="body-medium"
            >
              {item.text}
            </Text>
          </li>
        ))}
      </ul>
    </nav>
  );
};
