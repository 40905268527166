import { connect } from 'react-redux';

import { selectHRSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectHRSection(state).titleNavigation,
    inlineBanner: selectHRSection(state).inlineBanner,
    vacancies: selectHRSection(state).vacancies,
    anchor: selectHRSection(state).anchor,
  };
};

export const connector = connect(mapStateToProps);
