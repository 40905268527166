export enum EEndpoint {
  CheckCtn = '/api/CtnStatus/Get/',
}

export enum EUserType {
  Mobile = 0,
  Fttb = 1,
}

export enum EResponseStatus {
  IsBeelineUser = 1,
  IsNonBeelineUser = 2,
}

export enum EWidgetPayUrl {
  Prod = 'https://widgetpay1.beeline.ru/',
  Dev = '',
}
