import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - желтая шапка */
export const CardDescription: FC<{ label: string }> = ({ label }) => (
  <div className={cx('component')}>
    <Text variant="body-small">{label}</Text>
  </div>
);
