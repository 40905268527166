import { useIsDesktopDimension } from '@beef/ui-kit';
import React, { useEffect, useState } from 'react';

import { TInputErrorTexts } from '../components/Input/types';
import { TPaymentWidgetProps, TTexts } from '../types';
import { DEFAULT_PROPS } from '../utils';
import { TWithPaymentWidgetProps } from './types';

/**
 * Этот компонент был входной точкой при разработке
 * Позволил разрабатывать в изоляции от прочей логики
 */
export function withPaymentWidget({
  setContent,
  setInputState,
  getInitialCtn,
  onStateChange,
}: TWithPaymentWidgetProps) {
  return function (WrappedComponent: React.FC<Partial<TPaymentWidgetProps>>) {
    return (
      props: Partial<TPaymentWidgetProps> &
        Partial<{ texts: TTexts }> &
        Partial<{ animationData: string }> &
        Partial<{ inputErrorTexts: TInputErrorTexts }> &
        Partial<{
          userInfo: {
            ctn: string;
          };
        }>,
    ) => {
      const [_props, setProps] = useState<Partial<TPaymentWidgetProps>>(props);
      const isNotDesktop = !useIsDesktopDimension();

      useEffect(() => {
        setContent(props);

        setInputState({
          value: getInitialCtn(),
        });
      }, []);

      useEffect(() => {
        const nextProps: Partial<TPaymentWidgetProps> = {
          ..._props,
          inputProps: {
            ...DEFAULT_PROPS.inputProps,
            ..._props.inputProps,
            isMobile: isNotDesktop,
          },
        };

        onStateChange(nextProps, (__props) => {
          setProps(__props);
        });
      }, [isNotDesktop]);

      return <WrappedComponent {..._props} />;
    };
  };
}
