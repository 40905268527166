import { createMetric } from '@beef/metric';

import { tariffCardMetric } from 'pages/MainPage/blocks/TariffCardBlock/metric';
import { helpBlockMetric } from 'pages/MainPage/blocks/HelpBlock/metric';
import { commonMetric } from 'pages/MainPage/metric/common';

export const { Metric, withMetricContainer, decorateHandler, withMetric } = createMetric(
  {
    tariffCardMetric,
    helpBlockMetric,
    commonMetric,
  },
  'debug',
);
