import { useMemo } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { useTariffCardBlockType } from 'pages/MainPage/blocks/TariffCardBlock/components/Container/hooks/useTariffCardBlockType';

/** Хук для подготовки чипсин */
export const usePrepareChips = (title: string) => {
  const isMobile = useIsMobileDimension();
  const blockType = useTariffCardBlockType();
  const metricData = useMemo(() => {
    return JSON.stringify({ title, blockType });
  }, [title, blockType]);

  return { isMobile, metricData };
};
