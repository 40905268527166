import { ISection, SectionType } from 'pages/MainPage/types';
import { reduceSections } from 'pages/MainPage/utils/utils';

interface ConverterProps {
  data: {
    baseUrl: string;
    renderUrl: string;
    sections: ISection[];
  };
}

export const mainPageConvertor = ({ data }: ConverterProps) => {
  const { sections } = data;

  const sectionsParsed = reduceSections(sections);

  const sectionsFiltered = sections.filter((item) => item.section !== SectionType.MainSlider);

  return {
    sections,
    sectionsParsed,
    sectionsFiltered,
  };
};
