import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ServiceCard } from 'pages/MainPage/ui';

import styles from './styles.pcss';
import { ContentProps } from '../../types';

const cx = classNames.bind(styles);

export const Content: FC<{ cards: ContentProps[]; onCardClick?: (title: string) => void }> = ({
  cards,
  onCardClick,
}) => {
  return (
    <div className={cx('content-container')}>
      {cards.map((item) => (
        <ServiceCard
          className={cx('content-item')}
          hideChevron
          href={item.href}
          icon={item.iconUrl}
          isBlankUrlTarget={item.isBlankUrlTarget}
          label={item.label}
          mode="dark"
          onClick={onCardClick}
          title={item.title}
        />
      ))}
    </div>
  );
};
