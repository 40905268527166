import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';
import { Card } from './components/Card';
import { CardsBlockProps } from './types';

const cx = classNames.bind(styles);

export const CardsBlock: React.FC<CardsBlockProps> = ({ title, subtitle, cards, blockTitle }) => {
  return (
    <div className={cx('wrapper')}>
      <Text className={cx('title')} variant="headline-small">
        {title}
      </Text>
      <Text className={cx('subtitle')} color="secondary" variant="body-small">
        {subtitle}
      </Text>

      <div className={cx('cards')}>
        {cards.map((card) => (
          <Metric
            asChild
            data={JSON.stringify({
              blockTitle,
              elementTitle: card?.title,
            })}
            events={{ commonMetric: ['handleCardClick'] }}
            mode="click"
          >
            <Card
              backgroundSrc={card.backgroundSrc}
              backgroundSrcMobile={card.backgroundSrcMobile}
              href={card.href}
              iconSrc={card.iconSrc}
              isBlankUrlTarget={card.isBlankUrlTarget}
              key={card.title}
              subtitle={card.subtitle}
              title={card.title}
            />
          </Metric>
        ))}
      </div>
    </div>
  );
};
