import classNames from 'classnames/bind';
import React from 'react';

import styles from './styles.pcss';
import { TAnimationContainerProps } from './types';

const cn = classNames.bind(styles);

export const AnimationContainer: React.FC<TAnimationContainerProps> = ({ animationData }) => {
  return <div className={cn('wrapper')} style={{ backgroundImage: `url(${animationData})` }} />;
};
