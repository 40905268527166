import React, { useEffect, useState } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

interface UseScrollPositionResult {
  isRendered: boolean;
  transformStyle: string;
}

export const useScrollPosition = (
  sectionRef: React.RefObject<HTMLDivElement>,
): UseScrollPositionResult => {
  const [transformStyle, setTransformStyle] = useState('unset');
  const [isRendered, setIsRendered] = useState(false);
  const isMobile = useIsMobileDimension();

  const calculateTransformStyle = () => {
    const sectionPosition = sectionRef?.current?.getBoundingClientRect()?.height ?? 0;
    const calculateScroll = window.scrollY;
    const sectionPos = Math.max(0, window.innerHeight - sectionPosition - 95 - calculateScroll);

    return `translate(-50%, -${sectionPos}px)`;
  };

  useEffect(() => {
    if (!isMobile && sectionRef?.current && !isRendered) {
      // TODO: refactoring
      setTimeout(() => {
        setTransformStyle(calculateTransformStyle());
      }, 50);

      setTimeout(() => {
        setIsRendered(true);
      }, 100);
    }
  }, [isMobile, isRendered, sectionRef.current]);

  useEffect(() => {
    if (isMobile) return undefined;

    const handleScroll = () => {
      setTransformStyle(calculateTransformStyle());
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return { transformStyle, isRendered };
};
