import React from 'react';
import { Text } from '@carnica/ui-kit';
import { withRenderFlag } from '@beef/react';
import classNames from 'classnames/bind';

import { Navigation as _Navigation } from './components/Navigation';
import { NavigationWrapper } from './components/NavigationWrapper';
import { INavigationProps, TitleWithNavigationProps } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const Navigation: React.FC<INavigationProps & { shouldRender: boolean }> =
  withRenderFlag(_Navigation);

export const TitleWithNavigation: React.FC<TitleWithNavigationProps> = ({
  title,
  items,
  onNavigationItemClick,
}) => {
  return (
    <NavigationWrapper>
      <Text className={cx('title')} variant="display-medium">
        {title}
      </Text>
      <Navigation items={items} onClick={onNavigationItemClick} shouldRender={items.length > 0} />
    </NavigationWrapper>
  );
};
