export const enum CARD_TYPE {
  other = 'other',
  elk = 'elk',
  product = 'product',
  service = 'service',
  tariff = 'tariff',
  history = 'history',
}

export const enum ECard {
  other = 'другое',
  elk = 'личный кабинет',
  product = 'товары',
  service = 'услуги',
  tariff = 'тарифы',
}
export const enum STEP {
  recently = 'recently',
  result = 'result',
}

export const enum ATTRIBUTES_NAME {
  badges = 'бэйджи',
  elk = 'личный кабинет',
  preOrder = 'предзаказ',
  product = 'товар',
  service = 'услуга или акция',
  tariff = 'тариф',
}

export const TYPE_ANALYTICS_RESULT = {
  other: 'others',
  elk: 'elk',
  product: 'goods',
  service: 'tariffs',
  tariff: 'tariffs',
};

export const CATEGORIES = {
  other: 'Другое',
  elk: '1540',
  service: '1265',
  tariff: '101',
  product: '10',
  history: 'История',
};

export const UNIT = 'мес';

export const PRIORITY_LIST = ['tariffs', 'products', 'services', 'elk', 'others'];
export const CATEGORY_LIST = ['elk', 'tariffs', 'products', 'services', 'others'];

export const ERROR_CONTENT_TEXT = 'находит тот, кто ищет — \nи проверяет на опечатки';
export const EMPTY_CONTENT_TEXT = 'находит тот, кто ищет — \nи проверяет на опечатки';

export const TITLE_TEXT = 'поиск';
export const RESULT_TEXT = 'все результаты';

export const TITLES_BLOCK = {
  history: 'история поиска',
  recently: 'советуем посмотреть',
  product: 'товары',
  tariff: 'тарифы',
  service: 'услуги',
  other: 'другое',
  elk: 'личный кабинет',
};

export const IconSrcRobot = {
  desktop: 'https://static.beeline.ru/upload/images/b2c/img/23762_group.svg',
  mobile: 'https://static.beeline.ru/upload/images/b2c/img/23762_group.svg',
};

export const NONE_SCREEN = {
  contentEmptyText: 'находит тот, кто ищет —<br/>и проверяет на опечатки',
  contentErrorText: 'упс, что-то пошло не так<br/>повторите попытку',
};

export const ID_HELP_SEARCH = 'help-search';
