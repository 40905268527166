import React, { ReactNode, RefObject } from 'react';
import classNames from 'classnames/bind';

import { useScrollPosition } from '../../hooks/useScrollPosition';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface TabBarWrapperProps {
  children: ReactNode;
  isMobile: boolean;
  sectionRef: RefObject<HTMLDivElement>;
}

export const TabBarWrapper: React.FC<TabBarWrapperProps> = ({ sectionRef, children, isMobile }) => {
  const { transformStyle, isRendered } = useScrollPosition(sectionRef);
  return (
    <div
      className={cx('wrapper', isRendered && 'animate')}
      style={{
        transform: isMobile ? 'unset' : transformStyle,
        left: isMobile ? 'unset' : '50%',
        bottom: !isMobile ? 40 : 'unset',
        position: isMobile ? 'relative' : 'fixed',
      }}
    >
      {children}
    </div>
  );
};
