import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface DesktopBannerProps {
  action?: React.ReactNode;
  children?: React.ReactNode;
  legal?: React.ReactNode;
  style?: CSSProperties;
}

export const DesktopBanner: React.FC<DesktopBannerProps> = (props) => {
  const { action, legal, children, style } = props;

  return (
    <div className={cx('banner')} style={style}>
      <div className={cx('banner__content')}>{children}</div>
      <div className={cx('banner__footer')}>
        <div className={cx('banner__action')}>{action}</div>
        <div className={cx('banner__legal')}>{legal}</div>
      </div>
    </div>
  );
};
