import React, { FC } from 'react';
import classNames from 'classnames/bind';
import times from 'lodash/times';

import styles from './styles.pcss';
import { Skeleton } from './SkeletonBasic';

const cx = classNames.bind(styles);

export const SkeletonCell: FC = () => (
  <div className={cx('cell')}>
    <ul className={cx('cell-content')}>
      {times(5, (index) => (
        <li className={cx('details')} key={index}>
          <Skeleton className={cx('details-icon', 'figure__square')} />
          <div className={cx('details-info')}>
            <Skeleton className={cx('details-info__title', 'figure')} />
            <Skeleton className={cx('details-info__subtitle', 'figure')} />
          </div>
        </li>
      ))}
    </ul>
  </div>
);
