import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { ChipsGroup } from '@carnica/ui-kit';

import { ChipsElement } from 'pages/MainPage/blocks/HelpBlock/blocks/ChipsBlock/Chips';
import { scrollToBlock } from 'pages/MainPage/blocks/HelpBlock/ulils/scroll';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { ChipsType, TapsType } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ChipsBlock: React.FC<ChipsType> = ({ taps, onClick, anchorId }) => {
  const handleClick = useCallback((relatedSearch = '') => {
    onClick?.(relatedSearch);
    scrollToBlock(anchorId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('block-chips')}>
      <ChipsGroup
        className={cx('block-chips__group')}
        items={taps}
        layout={isMobile ? 'line' : 'wrap'}
        renderItem={(item: TapsType) => (
          <ChipsElement handleClick={handleClick} item={item} key={item.label} />
        )}
      />
    </div>
  );
};
