import React, { MouseEvent, memo } from 'react';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import style from './styles.pcss';

const cn = classNames.bind(style);

export type BrandType = {
  backgroundSize?: string;
  backgroundSizeMobile?: string;
  href: string;
  imgSrc: string;
  name?: string;
  onClick?: (url: string, event: MouseEvent<HTMLAnchorElement>) => void;
  target?: string;
};

export const BrandCard = memo(
  ({
    imgSrc,
    backgroundSize,
    backgroundSizeMobile,
    href,
    target,
    name,
    onClick = () => {},
  }: BrandType): JSX.Element => {
    const isMobile = useIsMobileDimension();

    return (
      <li
        className={cn('brand')}
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: isMobile ? backgroundSizeMobile : backgroundSize,
        }}
      >
        <a
          className={cn('link')}
          href={href}
          onClick={(event) => onClick(href, event)}
          target={target}
        >
          {name}
        </a>
      </li>
    );
  },
);
