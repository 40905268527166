import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISection, SectionType } from 'pages/MainPage/types';

const initialState = {};

const sectionsParsedSlice = createSlice({
  name: 'sectionsParsed',
  initialState,
  reducers: {
    mergeSections(state, action: PayloadAction<Partial<Record<SectionType, ISection>>>) {
      // Объединяем существующий объект с новым
      Object.assign(state, action.payload);
    },
  },
});

export const { mergeSections } = sectionsParsedSlice.actions;
export default sectionsParsedSlice.reducer;
