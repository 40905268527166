import { getContent } from '../../store';
import { paymentSlice, store } from '../../store/payment';
import { IState } from '../../types.utils';
import { TInputState } from './types';

export const InputState: IState<TInputState> = {
  getState: () => store.getState().input,
  onStateChange: (cb) =>
    store.subscribe(() => {
      cb(InputState.getState());
    }),
  setPartial: (next) => {
    const prev = InputState.getState();
    store.dispatch(paymentSlice.actions.setInput({ ...prev, ...next }));
  },
};

export const getInputState = InputState.getState.bind(InputState);
export const onInputChange = InputState.onStateChange.bind(InputState);
export const setInputState = InputState.setPartial.bind(InputState);
export const getInputTexts = () => {
  const { inputErrorTexts } = getContent();
  return inputErrorTexts ?? {};
};

export const setValue = (value: string | undefined) => {
  setInputState({ value });
};
export const setError = (error: string) => {
  setInputState({ error });
};
export const setIsLoading = (isLoading: boolean) => {
  setInputState({ isLoading });
};

export const getIsLoading = (state: TInputState) => !!state.isLoading;
export const getInputValue = (state: TInputState) => state.value ?? '';
export const getInputError = (state: TInputState) => state.error;
