import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { Input } from '@carnica/ui-kit';

import { useEventOutsideSearch } from 'pages/MainPage/blocks/HelpBlock/hooks/useEventOutsideSearch';

import { useInputQuery } from './hooks/useInputQuery';
import styles from './styles.pcss';
import { InputType } from './types';

const cx = classNames.bind(styles);

export const InputBlock: React.FC<InputType> = ({
  onSearch,
  onChange,
  onFocus,
  onClose,
  isOpen,
  placeholder,
}) => {
  const refSearch = useRef(null);

  const { query, ref, handleChange, handleClear, handleKeyDown, handleClick } = useInputQuery({
    onSearch,
    onChange,
    onFocus,
    isOpenModal: isOpen,
  });

  useEventOutsideSearch({
    handler: onClose,
    isOpen,
    ref: refSearch,
  });

  return (
    <div className={cx('input-block')} id="help-search" ref={refSearch}>
      <Input
        className={cx('input-block__search')}
        onChange={handleChange}
        onClear={handleClear}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        ref={ref}
        value={query}
      />
    </div>
  );
};
