import React from 'react';
import {
  IconArrowsDown,
  IconArrowsDownLeft,
  IconArrowsDownRight,
  IconArrowsLeft,
  IconArrowsRight,
  IconArrowsUp,
  IconArrowsUpLeft,
  IconArrowsUpRight,
} from '@carnica/graphics';

interface IconProps {
  color: 'primary' | 'secondary' | 'tertiary';
  condition:
    | 'bottom-left'
    | 'bottom-right'
    | 'down'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'up';
}

const Icon: React.FC<IconProps> = ({ condition, color }) => {
  switch (condition) {
    case 'down':
      return <IconArrowsDown color={color} size={16} />;
    case 'left':
      return <IconArrowsLeft color={color} size={16} />;
    case 'right':
      return <IconArrowsRight color={color} size={16} />;
    case 'up':
      return <IconArrowsUp color={color} size={16} />;
    case 'top-left':
      return <IconArrowsUpLeft color={color} size={16} />;
    case 'top-right':
      return <IconArrowsUpRight color={color} size={16} />;
    case 'bottom-left':
      return <IconArrowsDownLeft color={color} size={16} />;
    case 'bottom-right':
      return <IconArrowsDownRight color={color} size={16} />;
    default:
      return null;
  }
};

export default Icon;
