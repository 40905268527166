import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';
import { TypedUseSelectorHook, shallowEqual, useSelector } from 'react-redux';

import breakpointReducer from 'pages/MainPage/store/reducers';

import sectionsParsedSlice from './slices/sections';
import { RootState } from '../types';
import { helpSlice } from './slices/help';

const rootReducer = combineReducers({
  sections: (state = {}) => state,
  sectionsParsed: sectionsParsedSlice,
  renderUrl: (state = '/') => state,
  baseUrl: (state = '/') => state,
  breakpoint: breakpointReducer,
  helpSlice,
});

export const initializeStore = composeStoreInitializer(
  rootReducer,
) as unknown as typeof configureStore<typeof rootReducer>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useShallowEqualAppSelector: TypedUseSelectorHook<RootState> = (selector) =>
  useAppSelector(selector, shallowEqual);
