import React from 'react';
import { useMobileOS } from '@beef/react';

import { OS } from './types';

interface IContainerProps {
  children: React.ReactNode;
}

export const IOS: React.FC<IContainerProps> = ({ children }) => {
  const os = useMobileOS();

  if (os === OS.IOS || !os) {
    return children as JSX.Element;
  }

  return null;
};

export const Android: React.FC<IContainerProps> = ({ children }) => {
  const os = useMobileOS();

  if (os === OS.Android || !os) {
    return children as JSX.Element;
  }

  return null;
};
