import { useContext } from 'react';

import { TariffCardContext } from 'pages/MainPage/blocks/TariffCardBlock/components/Container';

/** Хук для использования контекста */
export const useTariffCardBlockType = () => {
  const blockType = useContext(TariffCardContext);
  if (blockType === undefined) {
    return '';
  }
  return blockType;
};
