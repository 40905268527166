import { withDataConverter } from '@beef/react';
import { Input as InputComponent } from '@carnica/ui-kit';
import React from 'react';

import { composeRoot } from './root';
import { TInputProps } from './types';

const { converter } = composeRoot();

export const Input: React.FC<TInputProps> = withDataConverter(converter)((props: TInputProps) => (
  // type='tel' не нужно убирать в конвертер, так как это деталь, которая не относится к интерфейсу (типу)
  <InputComponent {...props} type="tel" />
));
