export const simulateResize = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }
};

export const getWindowWidth = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }
  return 0;
};

export const getCurrentBreakpoint = (breakpoints: any[]) =>
  breakpoints.find((bp) => bp <= getWindowWidth());
