import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tag, Text } from '@carnica/ui-kit';

import { IPresetText } from 'pages/MainPage/blocks/EthernetCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type THeader = {
  partnerLogo?: string;
  presetText: IPresetText;
  subtitle?: string;
  tagLabel?: string;
};

/** Компонент - хедер карточки */
export const Header: FC<THeader> = ({ tagLabel, presetText, partnerLogo, subtitle }) => (
  <div className={cx('component')}>
    {partnerLogo && <img alt="" className={cx('partner-logo')} src={partnerLogo} />}
    {tagLabel && (
      <Tag size="m" view="light">
        {tagLabel}
      </Tag>
    )}
    <Text
      color="primary-invert"
      variant={presetText.isLargeText ? 'headline-medium' : 'headline-small'}
    >
      {presetText.text}
    </Text>
    <Text color="secondary-invert" variant="body-small">
      {subtitle}
    </Text>
  </div>
);
