import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface SectionProps {
  children: React.ReactNode;
  className?: string;
  heading?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ children, heading, style, className, ...otherProps }, ref) => {
    return (
      <div className={cx('wrapper', className)} ref={ref} style={style} {...otherProps}>
        <div className={cx('heading')}>{heading}</div>
        <div className={cx('content')}>{children}</div>
      </div>
    );
  },
);
