import React from 'react';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';
import { Container } from '../Container';

const cx = classNames.bind(styles);

export const TransitionContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobile = useIsMobileDimension();
  return (
    <div className={cx('wrapper')}>
      <Container
        style={{
          transform: `translateY(${isMobile ? 0 : '-40px'})`,
        }}
      >
        {children}
      </Container>
    </div>
  );
};
