import { paymentSlice, store } from './store/payment';
import { TContent } from './types';
import { IState } from './types.utils';

const ContentState: IState<TContent> = {
  getState: () => store.getState().content,
  onStateChange: (cb) => store.subscribe(() => cb(ContentState.getState())),
  setPartial: (next) => {
    const prev = ContentState.getState();
    store.dispatch(paymentSlice.actions.setContent({ ...prev, ...next }));
  },
};

export const getContent = ContentState.getState.bind(ContentState);
export const setContent = ContentState.setPartial.bind(ContentState);

export const getTexts = () => ContentState.getState()?.texts ?? {};
export const getAnimationData = () => ContentState.getState()?.animationData ?? '';
export const getWidgetPayUrl = () => ContentState.getState()?.widgetPayUrl ?? '';
export const getInitialCtn = () => ContentState.getState()?.userInfo?.ctn;
