import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface MobileBannerProps {
  action?: React.ReactNode;
  children?: React.ReactNode;
  legal?: React.ReactNode;
  style?: CSSProperties;
}

export const MobileBanner: React.FC<MobileBannerProps> = ({ legal, children, action, style }) => {
  return (
    <div className={cx('banner')} style={style}>
      <div className={cx('banner__content')}>{children}</div>
      <div className={cx('banner__actions')}>{action}</div>
      {legal && <div className={cx('banner__legal')}>{legal}</div>}
    </div>
  );
};
