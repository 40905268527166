import { compose } from '@beef/redux';
import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { RootState } from 'pages/MainPage/types';
import {
  selectCurrentElk,
  selectElk,
  selectIsRenderResultBlock,
  withProviderElkCardsMapper,
} from 'pages/MainPage/store/slices/help/selectors';
import { ECard, TITLES_BLOCK } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { CellCard, CommonBlock } from 'pages/MainPage/blocks/HelpBlock/blocks/CommonBlock';
import { withAnalyticsCategory } from 'pages/MainPage/blocks/HelpBlock/hocs/withAnalyticsCategory';
import { withAnalyticsCategories } from 'pages/MainPage/blocks/HelpBlock/hocs/withAnalyticsCategories';

export const ElkBlock = compose<FC<{ children?: ReactNode }>>(
  connect((state: RootState) => ({
    title: TITLES_BLOCK.elk,
    shouldRender: !!selectElk(state).length && selectIsRenderResultBlock(state),
  })),
  withProviderElkCardsMapper,
  withRenderFlag,
  withAnalyticsCategories(ECard.elk),
)(CommonBlock);

export const ElkCard = compose<FC<{ children?: ReactNode }>>(
  connect((state: RootState) => ({
    item: selectCurrentElk(state),
  })),
  withAnalyticsCategory(ECard.elk),
)(CellCard);
