import { connect } from 'react-redux';

import { selectMainSlider } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    menu: selectMainSlider(state).tabBar.menu,
    items: selectMainSlider(state).tabBar.items,
    breakpoint: state.breakpoint,
    sectionsParsed: state.sectionsParsed,
  };
};

export const connector = connect(mapStateToProps);
