import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTitle = {
  title: string;
};

/** Компонент - заголовок */
export const Title: React.FC<TTitle> = ({ title }) => {
  return (
    <Text className={cx('component')} color="primary" size="m">
      {title}
    </Text>
  );
};
