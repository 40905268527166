import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tag, Text } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type THeader = {
  presetText: string;
  tagLabel?: string;
};

/** Компонент - хедер карточки */
export const Header: FC<THeader> = ({ tagLabel, presetText }) => (
  <div className={cx('component')}>
    {tagLabel && (
      <Tag size="m" view="light">
        {tagLabel}
      </Tag>
    )}
    <Text color="primary-invert" size="m">
      {presetText}
    </Text>
  </div>
);
