import React from 'react';
import classNames from 'classnames/bind';
import { useIsDesktopDimension } from '@beef/ui-kit';
import { withRenderFlag } from '@beef/react';

import { Metric } from 'pages/MainPage/metric';

import { IComponentStoreLinks, IRenderFlag, Stores } from './types';
import styles from './styles.pcss';
import { Android as _Android, IOS as _IOS } from './utils';

const cx = classNames.bind(styles);

const Android: React.FC<IRenderFlag> = withRenderFlag(_Android);
const IOS: React.FC<IRenderFlag> = withRenderFlag(_IOS);

export const StoresIcons: React.FC<IComponentStoreLinks> = ({ storeLinks, icons, blockTitle }) => {
  const isDesktop = useIsDesktopDimension();

  return (
    <div className={cx('stores')}>
      <IOS shouldRender={storeLinks[Stores.IOS]}>
        <Metric
          asChild
          data={JSON.stringify({ buttonText: 'App Store', blockTitle })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <a
            aria-label={Stores.IOS}
            className={cx(!isDesktop ? 'stores__icon-full-width' : 'stores__icon')}
            href={storeLinks[Stores.IOS]}
          >
            <img
              alt=""
              className={cx(!isDesktop ? 'stores__icon-img-full' : 'stores__icon-img')}
              loading="lazy"
              src={!isDesktop ? icons.appStoreFull : icons.appStore}
            />
          </a>
        </Metric>
      </IOS>
      <Android shouldRender={storeLinks[Stores.Android]}>
        <Metric
          asChild
          data={JSON.stringify({ buttonText: 'Play Market', blockTitle })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <a
            aria-label={Stores.Android}
            className={cx('stores__icon')}
            href={storeLinks[Stores.Android]}
          >
            <img alt="" className={cx('stores__icon-img')} loading="lazy" src={icons.googleStore} />{' '}
          </a>
        </Metric>
      </Android>

      <Android shouldRender={storeLinks[Stores.Huawei]}>
        <Metric
          asChild
          data={JSON.stringify({ buttonText: 'Huawei AppGallery', blockTitle })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <a
            aria-label={Stores.Huawei}
            className={cx('stores__icon')}
            href={storeLinks[Stores.Huawei]}
          >
            <img alt="" className={cx('stores__icon-img')} loading="lazy" src={icons.appGallery} />
          </a>
        </Metric>
      </Android>

      <Android shouldRender={storeLinks[Stores.RuStore]}>
        <Metric
          asChild
          data={JSON.stringify({ buttonText: 'RU store', blockTitle })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <a
            aria-label={Stores.RuStore}
            className={cx('stores__icon')}
            href={storeLinks[Stores.RuStore]}
          >
            <img alt="" className={cx('stores__icon-img')} loading="lazy" src={icons.ruStore} />
          </a>
        </Metric>
      </Android>
    </div>
  );
};
