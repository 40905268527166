import { BREAKPOINT_1025, BREAKPOINT_SET } from '../utils/constants';

const initialState = BREAKPOINT_1025;

/**
 * Should only be called when breakpoint actually changes,
 * all that logic is up to AdaptivityProvider implementation
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
function breakpointReducer(state = initialState, action: { type: any; value: any }) {
  if (action?.type === BREAKPOINT_SET) {
    return action.value;
  }
  return state;
}

export default breakpointReducer;
