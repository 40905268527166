import { pathOr } from 'ramda';

import store, { headerStore2024 } from 'store';
import { getAccountType } from 'utils/analytics/auth';
import { ymPushParams } from 'utils/ym';

type PayloadYM = Record<string, unknown>;

export interface AnalyticsContextProps {
  account_type: string;
  page_path: string;
  page_title: string;
  page_url: string;
}

export const getCommonProps = (): AnalyticsContextProps => {
  const user = pathOr(null, ['external', 'user'], store.getState());

  return {
    page_url: window?.location?.href,
    page_path: window?.location?.pathname,
    page_title: document?.title,
    account_type: getAccountType(user),
  };
};

export const ymSendParams = (key: string, payload?: PayloadYM) => {
  const commonProps = getCommonProps();

  ymPushParams({ [key]: { ...payload, ...commonProps } });
};

export const getCommonPropsStore2024 = (): AnalyticsContextProps => {
  const headerData = headerStore2024()?.getState();

  return {
    page_url: window?.location?.href,
    page_path: window?.location?.pathname,
    page_title: document?.title,
    account_type: getAccountType(headerData?.header?.common?.auth?.user),
  };
};
