import React, { FC, ReactNode, createContext } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TContainer = {
  blockTypeString: string;
  children: ReactNode;
};

export const TariffCardContext = createContext<string | undefined>(undefined);

/** Компонент - обертка карточки */
export const ContainerProvider: FC<TContainer> = ({ blockTypeString, children }) => (
  <TariffCardContext.Provider value={blockTypeString}>
    <div className={cx('wrapper')}>{children}</div>
  </TariffCardContext.Provider>
);
