import React from 'react';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface ChipsGroupsProps {
  className?: string;
  items: IChipsItem[];
  onChange: (value: IChipsItem) => void;
  value: IChipsItem;
}

interface IChipsItem {
  key: string;
  label: string;
}

export const ChipsGroups: React.FC<ChipsGroupsProps> = ({ items, onChange, value, className }) => {
  return (
    <div className={cx('group', className)}>
      {items.map((item) => (
        <Button
          className={cx('group-item')}
          key={item.key}
          onClick={() => onChange(item)}
          size="m"
          view={item.key === value?.key ? 'tertiary' : 'secondary-invert'}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};
