import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { IPriceText } from 'pages/MainPage/blocks/EthernetCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Футер карточки */
export const Footer: React.FC<IPriceText> = ({ oldPrice, currentPrice }) => {
  return (
    <div className={cx('component')}>
      <Text className={cx('old-price')} color="tertiary" size="xs">
        {oldPrice}
      </Text>
      <Text color="primary-invert" size="xs">
        {currentPrice}
      </Text>
    </div>
  );
};
