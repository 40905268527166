import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';

import { useAdaptiveSize } from './hooks/useAdaptiveSize';

type Breakpoint = number;

interface WithAdaptivityProps {
  breakpoints: Breakpoint[];
  setBreakpoint: (breakpoint: Breakpoint) => void;
}

// Пропсы, передаваемые в компонент через connect
interface DispatchProps {
  onSetBreakpoint: (breakpoint: Breakpoint) => void;
}

// HOC для добавления адаптивности
export const withAdaptivity =
  ({ breakpoints, setBreakpoint }: WithAdaptivityProps) =>
  <WrappedProps extends NonNullable<unknown>>(WrappedComponent: ComponentType<WrappedProps>) => {
    // Компонент ResizeListener с типами, подхватываемыми через DispatchProps и WrappedProps
    const ResizeListener: React.FC<WrappedProps & DispatchProps> = (props) => {
      const { onSetBreakpoint, ...restProps } = props;
      const breakpoint = useAdaptiveSize(breakpoints);

      useEffect(() => {
        onSetBreakpoint(breakpoint);
      }, [breakpoint, onSetBreakpoint]);

      return <WrappedComponent {...(restProps as unknown as WrappedProps)} />;
    };

    // Подключение к Redux с нужными типами и возврат обернутого компонента
    return connect<null, DispatchProps, WrappedProps>(null, { onSetBreakpoint: setBreakpoint })(
      ResizeListener as ComponentType<any>,
    );
  };
