import React, { forwardRef, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { cnBem, combineRefs } from '@carnica/utils';
import { useBlockScroll, useKeys } from '@carnica/utils/hooks';
import { EKeyCodes } from '@carnica/utils/types';
import { ThemedPortal } from '@carnica/ui-kit';

import styles from './styles.pcss';
import { ModalPropCloseOptions, ModalProps } from './types';
import { ModalContent } from './ModalContent';

const cnModal = cnBem('modal', styles);

const defaultCloseOptions: ModalPropCloseOptions = {
  overlay: true,
  button: true,
  escape: true,
};

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    isOpen,
    theme,
    style,
    overlayClassName,
    portalClassName,
    portalRef,
    disableScroll = true,
    portalProps,
    onClose,
    closeOptions = defaultCloseOptions,
    ...otherProps
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useKeys({
    [EKeyCodes.Escape]: (e: KeyboardEvent) => isOpen && closeOptions.escape && onClose?.(e),
  });

  useBlockScroll(disableScroll && isOpen);

  return (
    <Transition in={isOpen} nodeRef={wrapperRef} timeout={300} unmountOnExit>
      {(animate) => (
        <ThemedPortal
          {...(portalProps ?? {})}
          aria-modal
          className={cnModal(null, [portalClassName])}
          ref={combineRefs([wrapperRef, portalRef])}
          role="dialog"
          style={{ zIndex: typeof style?.zIndex === 'number' ? style.zIndex : 1 }}
          theme={theme}
        >
          <div
            aria-hidden="true"
            className={cnModal('overlay', { animate }, overlayClassName)}
            ref={overlayRef}
          >
            <ModalContent
              animate={animate}
              closeOptions={closeOptions}
              isOpen={isOpen}
              onClose={onClose}
              overlayRef={overlayRef}
              ref={ref}
              style={style}
              {...otherProps}
            />
          </div>
        </ThemedPortal>
      )}
    </Transition>
  );
});
