import React from 'react';
import { useMobileOS } from '@beef/react';
import { MobileAppButton } from '@beef/ui-kit';

import { OS } from '../types';

interface IContainerProps {
  children: ReturnType<typeof MobileAppButton>;
}

export const IOS: React.FC<IContainerProps> = ({ children }) => {
  const os = useMobileOS();

  return os === OS.IOS || !os ? children : null;
};

export const Android: React.FC<IContainerProps> = ({ children }) => {
  const os = useMobileOS();

  return os === OS.Android || !os ? children : null;
};

export const Desktop: React.FC<IContainerProps> = ({ children }) => {
  const os = useMobileOS();

  return !os ? children : null;
};
