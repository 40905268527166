import { useCallback, useEffect, useRef, useState } from 'react';

import { ISectionParsed } from 'pages/MainPage/types';

const useNearestSection = (sectionIds: string[] | undefined, sectionsParsed: ISectionParsed) => {
  const [activeSection, setActiveSection] = useState<null | string>(null);
  const sectionRefs = useRef<(HTMLElement | null)[]>([]);

  const handleScroll = useCallback(() => {
    if (!sectionIds) return;

    // Проверяем, если скролл больше 10 пикселей
    if (window.scrollY <= 10) {
      setActiveSection(null);
      return;
    }

    let closestSection: null | string = null;
    let closestDistance = Infinity;

    sectionRefs.current.forEach((element, index) => {
      if (element) {
        const distance = Math.abs(element.getBoundingClientRect().top);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestSection = sectionIds[index];
        }
      }
    });

    if (closestSection !== activeSection) {
      setActiveSection(closestSection);
    }
  }, [activeSection, sectionIds]);

  useEffect(() => {
    if (!sectionIds) {
      sectionRefs.current = [];
      return;
    }

    sectionRefs.current = sectionIds.map((id) => document.getElementById(id));
    handleScroll();
  }, [sectionIds, sectionsParsed, handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return activeSection;
};

export { useNearestSection };
