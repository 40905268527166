import React, { memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { SkeletonBasicPropsType } from './types';

const cx = classNames.bind(styles);

export const Skeleton = memo(({ className, testName = 'Skeleton' }: SkeletonBasicPropsType) => (
  <div className={cx('skeleton', className)} data-component={testName} />
));
