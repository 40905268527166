import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTitle = {
  children: React.ReactNode;
};

export const Title: React.FC<TTitle> = ({ children }) => {
  return (
    <Text className={cx('component')} color="primary" size="m">
      {children}
    </Text>
  );
};
