import { YMMetricHelper } from '@beef/metric';

import { getTextFromHtml } from 'utils/format-string';
import { getCommonPropsStore2024 } from 'utils/analytics/ymBase';

export class CommonMetric extends YMMetricHelper {
  protected readonly MAIN_PAGE_PARAMS_KEY = 'main';

  protected readonly MAIN_BANNERS_PARAMS_KEY = 'main_banners_ev_';

  protected setMainPageParams = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.MAIN_PAGE_PARAMS_KEY]: {
          ...params,
        },
      };

    const commonPropsYM = getCommonPropsStore2024();

    return {
      [this.MAIN_PAGE_PARAMS_KEY]: {
        ...commonPropsYM,
        ...params,
      },
    };
  };

  protected setMainBannerParams = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.MAIN_BANNERS_PARAMS_KEY]: {
          ...params,
        },
      };

    const commonPropsYM = getCommonPropsStore2024();

    return {
      [this.MAIN_BANNERS_PARAMS_KEY]: {
        ...commonPropsYM,
        ...params,
      },
    };
  };

  handleMainPageView() {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'view',
        item_type: 'page',
      }),
    );
  }

  handleBlockView(block_title: string) {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'view',
        item_type: 'block',
        block_title: getTextFromHtml(block_title),
      }),
    );
  }

  handleIconClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'icon',
        icon_name: getTextFromHtml(parsedData.iconName),
        object_type: 'block',
        block_title: getTextFromHtml(parsedData.blockTitle),
      }),
    );
  }

  handleBannerView(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'view',
        item_type: 'banner',
        block_title: getTextFromHtml(parsedData.blockTitle),
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        object_type: 'block',
        banner_type: 'static',
      }),
    );
  }

  handleBannerClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'banner',
        block_title: getTextFromHtml(parsedData.blockTitle),
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        object_type: 'block',
        banner_type: 'static',
      }),
    );
  }

  handleBannerButtonClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'button',
        block_title: getTextFromHtml(parsedData.blockTitle),
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        button_text: getTextFromHtml(parsedData.buttonText),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        object_type: 'banner',
        banner_type: 'static',
      }),
    );
  }

  handleCardClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'element',
        block_title: getTextFromHtml(parsedData.blockTitle),
        element_title: getTextFromHtml(parsedData.elementTitle),
        object_type: 'block',
      }),
    );
  }

  handleBlockNavClick(link_text: string, block_title: string) {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'link',
        link_text: getTextFromHtml(link_text),
        object_type: 'block',
        block_title: getTextFromHtml(block_title),
      }),
    );
  }

  handleSquareCardClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'element',
        element_title: getTextFromHtml(parsedData.title),
        object_type: 'block',
        block_title: getTextFromHtml(parsedData.blockTitle),
      }),
    );
  }

  handleButtonClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'button',
        button_text: getTextFromHtml(parsedData.buttonText),
        object_type: 'block',
        block_title: getTextFromHtml(parsedData.blockTitle),
      }),
    );
  }

  handleTabClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'tab',
        tab_text: getTextFromHtml(parsedData.tabText),
        object_type: 'block',
        block_title: getTextFromHtml(parsedData.blockTitle),
      }),
    );
  }

  handleTabCardClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'element',
        element_title: getTextFromHtml(parsedData.elementTitle),
        tab_name: getTextFromHtml(parsedData.tabName),
        object_type: 'block',
        block_title: getTextFromHtml(parsedData.blockTitle),
      }),
    );
  }

  handleTabBarClick(tab_text: string) {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'tab',
        tab_text: getTextFromHtml(tab_text),
      }),
    );
  }

  handleTabBarButtonClick() {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'button',
        button_name: 'navigation_menu',
      }),
    );
  }

  handleTabBarMenuClick(element_title: string) {
    this.sendParamsEvent(
      this.setMainPageParams({
        action: 'click',
        item_type: 'element',
        element_title: getTextFromHtml(element_title),
      }),
    );
  }

  handleTopBannerView(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'view',
        item_type: 'banner',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_id: `${parsedData.bannerId}`,
        banner_type: 'slider',
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'block',
        block_name: 'main_top',
      }),
    );
  }

  handleTopBannerClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'click',
        item_type: 'banner',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_id: `${parsedData.bannerId}`,
        banner_type: 'slider',
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'block',
        block_name: 'main_top',
      }),
    );
  }

  handleTopBannerButton(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'click',
        item_type: 'button',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_id: `${parsedData.bannerId}`,
        banner_type: 'slider',
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'banner',
        block_name: 'main_top',
        button_text: `${parsedData.buttonText}`,
      }),
    );
  }

  handleInlineBannerView(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'view',
        item_type: 'banner',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_id: `${parsedData.bannerId}`,
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'block',
        banner_type: 'slider',
        block_title: `${parsedData.blockTitle}`,
      }),
    );
  }

  handleInlineBannerClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'click',
        item_type: 'banner',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_id: `${parsedData.bannerId}`,
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'block',
        banner_type: 'slider',
        block_title: `${parsedData.blockTitle}`,
      }),
    );
  }

  handleInlineBannerButton(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMainBannerParams({
        action: 'click',
        item_type: 'button',
        banner_title: getTextFromHtml(parsedData.bannerTitle),
        banner_url: getTextFromHtml(parsedData.bannerUrl),
        banner_description: getTextFromHtml(parsedData.bannerDescription),
        banner_id: `${parsedData.bannerId}`,
        banner_position: `${parsedData.bannerPosition}`,
        object_type: 'banner',
        banner_type: 'slider',
        block_title: `${parsedData.blockTitle}`,
        button_text: `${parsedData.buttonText}`,
      }),
    );
  }
}

export const commonMetric = new CommonMetric({
  counterId: 26001372,
  isEcommerce: false,
  debug: true,
});
