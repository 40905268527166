/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { DIMENSION_GROUPS, useGetDimensionGroup, useIsDesktopDimension } from '@beef/ui-kit';

type Props = { children?: React.ReactNode };
const Desktop: React.FC<Props> = ({ children }) => {
  const isDesktop = useIsDesktopDimension();

  return isDesktop ? <>{children}</> : null;
};

const Mobile: React.FC<Props> = ({ children }) => {
  const group = useGetDimensionGroup();
  return group === DIMENSION_GROUPS.mobile || group === DIMENSION_GROUPS.tablet ?
      <>{children}</>
    : null;
};

export const View = { Desktop, Mobile };
