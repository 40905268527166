import React from 'react';
import classNames from 'classnames/bind';
import { IconCloseRoundStroked } from '@carnica/graphics';
import { Button } from '@carnica/ui-kit';
import { connect } from 'react-redux';

import { Modal } from 'pages/MainPage/blocks/HelpBlock/blocks/Modal';
import { ModalLayoutType } from 'pages/MainPage/blocks/HelpBlock/blocks/ModalLayout/types';
import { ActionSearch } from 'pages/MainPage/store/slices/help/actions';
import { RootState } from 'pages/MainPage/types';
import { selectIsOpenModal } from 'pages/MainPage/store/slices/help/selectors';

import style from './styles.pcss';

const cn = classNames.bind(style);

const _ModalLayout: React.FC<ModalLayoutType> = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      actionBarNode={
        <Button
          className={cn('action-button')}
          iconLeft={IconCloseRoundStroked}
          onClick={onClose}
          onlyIcon
          size="m"
          view="tertiary"
        />
      }
      closeOptions={{ button: false, overlay: true, escape: true }}
      disableOutsideClickHandler
      isOpen={isOpen}
      onClose={onClose}
      style={{ zIndex: 555 }}
      theme="light"
    >
      {children}
    </Modal>
  );
};

const mapDispatchToProps = {
  onClose: () => ActionSearch.toggleOpenModal(false),
};

const mapStateToProps = (state: RootState) => ({
  isOpen: selectIsOpenModal(state),
});

export const HelpSearchModalLayout = connect(mapStateToProps, mapDispatchToProps)(_ModalLayout);
