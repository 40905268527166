import { connect } from 'react-redux';

import { selectAppSectionBlock } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    ...selectAppSectionBlock(state),
  };
};

export const connector = connect(mapStateToProps);
