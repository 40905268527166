import { withZones } from '@beef/react';
import classNames from 'classnames/bind';
import React from 'react';
import { cnTheme } from '@carnica/themes';

import { BottomCardLayout } from './BottomCardLayout';
import { TopCardLayout } from './TopCardLayout';
import { EChild } from './constants';
import styles from './styles.pcss';
import { TWidgetLayout, TZones } from './types';

const cn = classNames.bind(styles);

export const WidgetLayout = withZones(Object.keys(EChild) as EChild[])(({
  zones,
  bottomCardProps,
  topCardProps,
}: TWidgetLayout & TZones) => {
  return (
    <div className={cn('extra-wrapper', cnTheme())}>
      <div className={cn('wrapper')}>
        <TopCardLayout {...topCardProps}>
          <TopCardLayout.TopCardInput>{zones.TopCardInput}</TopCardLayout.TopCardInput>
          <TopCardLayout.TopCardButton>{zones.TopCardButton}</TopCardLayout.TopCardButton>
        </TopCardLayout>
        <BottomCardLayout {...bottomCardProps}>
          <BottomCardLayout.BottomCardButton>
            {zones.BottomCardButton}
          </BottomCardLayout.BottomCardButton>
        </BottomCardLayout>
      </div>
    </div>
  );
});
