import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const StickyIconBlock: React.FC<{ stickyImageUrl: string }> = ({ stickyImageUrl }) => {
  return (
    <div className={cx('sticky-wrapper')}>
      <img alt="" src={stickyImageUrl} />
    </div>
  );
};
