import { connect } from 'react-redux';

import { ActionSearch } from 'pages/MainPage/store/slices/help/actions';
import { InputBlock } from 'pages/MainPage/blocks/HelpBlock/blocks/SearchControl/InputBlock';
import { RootState } from 'pages/MainPage/types';
import {
  selectIsOpenModal,
  selectPlaceholderHelp,
} from 'pages/MainPage/store/slices/help/selectors';

import { Input } from './Input';

const mapDispatchToProps = {
  onSearch: ActionSearch.fetchSearchData,
  onChange: ActionSearch.setQuery,
  onFocus: ActionSearch.fetchSearchStart,
  onClose: () => ActionSearch.toggleOpenModal(false),
};

const mapStateToProps = (state: RootState) => ({
  isOpen: selectIsOpenModal(state),
  placeholder: selectPlaceholderHelp(state),
});

export const SearchInput = connect(mapStateToProps, mapDispatchToProps)(Input);

export const SearchInputBlock = connect(mapStateToProps, mapDispatchToProps)(InputBlock);
