import React, { FC, memo } from 'react';
import { MetricAttrs } from '@beef/metric';

import { ThunkDispatchType } from 'pages/MainPage/store/common';
import { Metric } from 'pages/MainPage/metric';
import { CardCellType } from 'pages/MainPage/blocks/HelpBlock/types';
import { ECard } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';

type Props = {
  dispatch: ThunkDispatchType;
  item: CardCellType;
  metrics?: MetricAttrs;
};

export const withAnalyticsCategory = (categoryTitle: ECard) => (WrappedComponent: FC<Props>) =>
  memo((props: Props) => {
    const { title } = props.item;
    const metricData = {
      categoryTitle,
      elementTitle: title,
    };

    return (
      <Metric
        asChild
        data={JSON.stringify(metricData)}
        events={{ helpBlockMetric: ['handleCategoryElementClick'] }}
        mode="click"
      >
        {({ dataMetricAttrs }) => <WrappedComponent {...props} metrics={dataMetricAttrs} />}
      </Metric>
    );
  });
