import { connect } from 'react-redux';

import { selectMainSlider } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    ...selectMainSlider(state).carousel,
  };
};

export const connector = connect(mapStateToProps);
