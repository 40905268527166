import React from 'react';
import { Text } from '@carnica/ui-kit';
import { ChevronRightIcon } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { IconComponent } from 'pages/CarnicaDSLandings/CapabilitiesAtZero/components/SubscriberBlock/SubscriberCard/types';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

interface IServiceCardProps {
  className?: string;
  hideChevron?: boolean;
  href?: string;
  icon: IconComponent | string;
  isBlankUrlTarget: boolean;
  label: string;
  mode: 'dark' | 'light' | 'white';
  onClick?: (title: string) => void;
  title: string;
}

export const ServiceCard: React.FC<IServiceCardProps> = ({
  title,
  label,
  mode,
  icon,
  href,
  isBlankUrlTarget,
  hideChevron = false,
  className,
  onClick,
}) => {
  const Tag = href ? 'a' : 'div';
  const renderIcon = (Icon: IconComponent | string) => {
    if (!Icon) {
      return null;
    }

    return typeof Icon === 'string' ?
        <img alt="Icon" className={cn('icon', mode)} src={Icon} />
      : <Icon size={24} />;
  };

  return (
    <Tag
      className={cn('card', mode, className)}
      href={href}
      onClick={() => onClick?.(title)}
      target={MenuUrlTarget.get(isBlankUrlTarget)}
    >
      <div className={cn('icon-wrapper', mode)}>{renderIcon(icon)}</div>
      <div className={cn('header', mode)}>
        <Text className={cn('label', mode)} size="m">
          {title}
        </Text>
      </div>
      <Text className={cn('link', mode)} color="secondary" size="xs" tagName="span">
        {label}
        {!hideChevron && <ChevronRightIcon size={24} />}
      </Text>
    </Tag>
  );
};
