import React from 'react';
import classNames from 'classnames/bind';

import { ICard } from 'pages/MainPage/components/Devices/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
export const Card: React.FC<ICard> = ({ image, className }) => {
  return (
    <div className={cx('card', className)}>
      <img alt="изображение устройства" className={cx('image')} src={image} />
    </div>
  );
};
