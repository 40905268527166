import React from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  mode?: 'dark' | 'light';
  style?: React.CSSProperties;
}

export const AppLayout: React.FC<LayoutProps> = ({
  children,
  style,
  mode = 'light',
  className,
}) => {
  return (
    <div className={cx('layout', cnTheme(mode), className)} style={style}>
      {children}
    </div>
  );
};
