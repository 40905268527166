import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { SuggestionsResponseData } from 'pages/MainPage/blocks/HelpBlock/types';
import { prepareResult } from 'pages/MainPage/blocks/HelpBlock/ulils/prepareResultData';
import { STEP } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { RootState } from 'pages/MainPage/types';

import { requestConfig } from '../../../blocks/HelpBlock/api/config';
import { selectIsOpenModal, selectRequestUrl } from './selectors';

export const SEARCH_ACTION_TYPES = {
  SEARCH_SET_QUERY: 'setQuery',
  SEARCH_SET_STEP: 'setStep',
  SEARCH_TOGGLE_OPEN_MODAL: 'toggleOpenModalSearch',
  FETCH_ANY_QUERY: 'fetch_any_query',
  FETCH_WITH_TABS: 'fetch_with_tabs',
  FETCH_START: 'fetch_start',
};

export const ActionSearch = {
  setQuery: createAction<string>(SEARCH_ACTION_TYPES.SEARCH_SET_QUERY),
  setStep: createAction<STEP>(SEARCH_ACTION_TYPES.SEARCH_SET_STEP),
  toggleOpenModal: createAction<boolean>(SEARCH_ACTION_TYPES.SEARCH_TOGGLE_OPEN_MODAL),
  fetchSearchData: createAsyncThunk(
    SEARCH_ACTION_TYPES.FETCH_ANY_QUERY,
    async (query: string, { dispatch, getState, rejectWithValue }) => {
      const state = getState() as RootState;

      try {
        const url = selectRequestUrl(state);
        const searchUrl = new URL(url);
        searchUrl.searchParams.set('st', query);
        const { data } = await axios.get<SuggestionsResponseData>(
          searchUrl.toString(),
          requestConfig,
        );

        return prepareResult(data);
      } catch (error: unknown) {
        const err = error as AxiosError;
        return rejectWithValue(err.response);
      } finally {
        const step = query ? STEP.result : STEP.recently;
        dispatch(ActionSearch.setStep(step));
      }
    },
  ),
  fetchSearchDataWithTabs: createAsyncThunk(
    SEARCH_ACTION_TYPES.FETCH_WITH_TABS,
    async (query: string, { dispatch }) => {
      dispatch(ActionSearch.setQuery(query));
      await dispatch(ActionSearch.fetchSearchData(query));
    },
  ),
  fetchSearchStart: createAsyncThunk(
    SEARCH_ACTION_TYPES.FETCH_START,
    async (query: string, { dispatch, getState }) => {
      try {
        const state = getState() as RootState;

        const isOpen = selectIsOpenModal(state);

        if (!isOpen) {
          dispatch(ActionSearch.toggleOpenModal(true));
        }
        await dispatch(ActionSearch.fetchSearchDataWithTabs(query));
      } catch (e) {
        throw new Error('error');
      }
    },
  ),
};
