import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { CommonBlockType, OtherPropsType } from '../types';
import styles from '../styles.pcss';

const cx = classNames.bind(styles);
export const CommonBlock: React.FC<CommonBlockType> = ({
  title,
  children,
  isHistory,
  className,
  ...otherProps
}) => {
  const { dispatch, shouldRender, ...metrics } = otherProps as OtherPropsType;

  return (
    <div className={cx('common-block')} {...(metrics as React.HTMLAttributes<HTMLDivElement>)}>
      {title && (
        <Text className={cx('common-block__title')} color="tertiary-invert" size="xs">
          {title}
        </Text>
      )}
      <ul
        className={cx('common-block__list', { 'common-block__list-history': isHistory }, className)}
      >
        {children}
      </ul>
    </div>
  );
};
