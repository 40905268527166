import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';
import Arrow from './assets/arrow.svg';

const cx = classnames.bind(styles);

export interface SquareBlockProps {
  backgroundImage: string;
  backgroundPosition: string;
  backgroundPositionMobile: string;
  backgroundSize: string;
  backgroundSizeMobile: string;
  href: string;
  isBlankUrlTarget: boolean;
  subtitle: string;
  title: string;
}

export const SquareBlock: React.FC<SquareBlockProps> = ({
  backgroundPosition,
  backgroundSize,
  backgroundSizeMobile,
  backgroundPositionMobile,
  backgroundImage,
  isBlankUrlTarget,
  subtitle,
  title,
  href,
  ...otherProps
}) => {
  const isMobile = useIsMobileDimension();
  return (
    <a
      className={cx('wrapper')}
      href={href}
      style={{
        backgroundPosition: isMobile ? backgroundPositionMobile : backgroundPosition,
        backgroundSize: isMobile ? backgroundSizeMobile : backgroundSize,
        backgroundImage,
      }}
      target={MenuUrlTarget.get(isBlankUrlTarget)}
      {...otherProps}
    >
      <div>
        <Text className={cx('title')} color="primary" variant="display-small">
          {title}
        </Text>
        <Text className={cx('subtitle')} color="secondary" variant="body-medium">
          {subtitle}
        </Text>
      </div>
      <div className={cx('button')}>
        <Arrow />
      </div>
    </a>
  );
};
