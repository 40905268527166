import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { Card } from '../Card';

const cx = classNames.bind(styles);

interface CardsListProps {
  cardsImages: string[];
}

export const CardsList: React.FC<CardsListProps> = ({ cardsImages }) => {
  return (
    <div className={cx('wrapper')}>
      {cardsImages.map((item) => (
        <Card className={cx('card-transform')} image={item} />
      ))}
    </div>
  );
};
