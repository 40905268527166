import React from 'react';

import { SearchResult } from 'pages/MainPage/blocks/HelpBlock/SearchResult';
import { View } from 'pages/MainPage/components/View';
import { ModalSearch } from 'pages/MainPage/blocks/HelpBlock/ModalSearch';
import { FooterHelpBlock } from 'pages/MainPage/blocks/HelpBlock/blocks/FooterHelpBlock';
import { Anchor } from 'pages/MainPage/components/Anchor';

import {
  Block,
  ChipsBlockRC,
  Container,
  HelpSearchModalLayout,
  SearchInput,
  SearchInputBlock,
  Section,
  Title,
} from './blocks';

export const HelpBlock: React.FC<{ anchor: string }> = ({ anchor }) => (
  <>
    <Block>
      <Anchor anchorId={anchor} />
      <Title />
      <SearchInputBlock />
      <ChipsBlockRC />
      <View.Desktop>
        <FooterHelpBlock />
      </View.Desktop>
    </Block>
    <View.Desktop>
      <ModalSearch />
    </View.Desktop>
    <View.Mobile>
      <HelpSearchModalLayout>
        <Container>
          <Section>
            <SearchInput />
          </Section>
          <SearchResult />
        </Container>
      </HelpSearchModalLayout>
    </View.Mobile>
  </>
);
