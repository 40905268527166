import React from 'react';

import { AppBlock } from 'pages/MainPage/blocks/AppBlock';
import { AppBlockProps } from 'pages/MainPage/blocks/AppBlock/types';

import { connector } from './connector';

const _AppSection: React.FC<AppBlockProps> = (props) => {
  return <AppBlock {...props} />;
};

export const AppSection = connector(_AppSection);
