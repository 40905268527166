import React, { FC, memo } from 'react';
import { MetricAttrs } from '@beef/metric';

import { ThunkDispatchType } from 'pages/MainPage/store/common';
import { Metric } from 'pages/MainPage/metric';
import { ECard } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';

type Props = {
  dispatch: ThunkDispatchType;
  metrics?: MetricAttrs;
};

export const withAnalyticsCategories = (categoryTitle: ECard) => (WrappedComponent: FC<Props>) =>
  memo((props: Props) => {
    return (
      <Metric
        asChild
        data={categoryTitle}
        events={{ helpBlockMetric: ['handleViewCategoryBlock'] }}
        mode="viewport"
        once
        threshold={0.3}
      >
        {({ dataMetricAttrs }) => <WrappedComponent {...props} {...dataMetricAttrs} />}
      </Metric>
    );
  });
