import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TContainer = {
  children: ReactNode;
};

/** Компонент-обертка карточки */
export const CardContainer: FC<TContainer> = ({ children }) => (
  <div className={cx('wrapper')}>{children}</div>
);
