import { withZones } from '@beef/react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import { AnimationContainer } from '../../AnimationContainer';
import { EChild } from '../constants';
import styles from './styles.pcss';
import { TBottomCardLayoutProps, TZones } from '../types';

const cn = classNames.bind(styles);

export const CardWrapper: React.FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  return (
    <div className={cn('bottom-card')}>
      <div className={cn('description')}>{children}</div>
    </div>
  );
};

const _BottomCardLayout = ({
  zones,
  text: { primary, secondary },
  animationContainerProps,
}: TBottomCardLayoutProps & TZones) => {
  return (
    <CardWrapper>
      <div className={cn('content')}>
        <div className={cn('text')}>
          <Text className={cn('primary')} variant="body-medium">
            {primary}
          </Text>
          <Text className={cn('secondary')} color="secondary" variant="body-small">
            {secondary}
          </Text>
        </div>
        {zones[EChild.BottomCardButton]}
      </div>
      <div className={cn('animation-container')}>
        <AnimationContainer {...animationContainerProps} />
      </div>
    </CardWrapper>
  );
};

export const BottomCardLayout = withZones(Object.keys(EChild) as EChild[])(_BottomCardLayout);
