import React, { useEffect, useMemo, useState } from 'react';

import {
  useIsDesktopDimension,
  useIsMobileDimension,
  useIsTabletDimension,
} from 'utils/hooks/useGetDimensionGroup';

interface IContainerProps {
  children: React.ReactNode;
}

interface ResponsiveContainerProps {
  end: 'desktop' | 'mobile' | 'tablet';
  start: 'desktop' | 'mobile' | 'tablet';
}

export const Mobile: React.FC<IContainerProps> = ({ children }) => {
  const isMobile = useIsMobileDimension();

  return isMobile ? children : null;
};

export const Tablet: React.FC<IContainerProps> = ({ children }) => {
  const isTablet = useIsTabletDimension();

  return isTablet ? children : null;
};

export const Desktop: React.FC<IContainerProps> = ({ children }) => {
  const isDesktop = useIsDesktopDimension();

  return isDesktop ? children : null;
};

export const AdaptivityContainer: React.FC<
  {
    maxWidth?: number;
    minWidth?: number;
  } & IContainerProps
> = ({ children, maxWidth = 10000000, minWidth = 0 }) => {
  const [clientWidth, setClientWidth] = useState<number>(
    typeof window === 'undefined' ? 0 : window.innerWidth,
  );

  const memoizedMaxWidth = useMemo(() => maxWidth, [maxWidth]);
  const memoizedMinWidth = useMemo(() => minWidth, [minWidth]);

  const isInRange = useMemo(
    () => clientWidth >= memoizedMinWidth && clientWidth <= memoizedMaxWidth,
    [clientWidth, memoizedMinWidth, memoizedMaxWidth],
  );

  useEffect(() => {
    const handleResize = () => {
      setClientWidth(typeof window === 'undefined' ? 0 : window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isInRange && typeof window !== 'undefined' ? children : null;
};

export const ResponsiveContainer: React.FC<ResponsiveContainerProps & IContainerProps> = ({
  children,
  end,
  start,
}) => {
  const isMobile = useIsMobileDimension();
  const isTablet = useIsTabletDimension();
  const isDesktop = useIsDesktopDimension();

  const showChildren = useMemo(() => {
    let result = false;
    switch (`${start}-${end}`) {
      case 'mobile-tablet':
        result = isMobile || isTablet;
        break;
      case 'tablet-desktop':
        result = isTablet || isDesktop;
        break;
      case 'mobile-desktop':
        result = isMobile || isTablet || isDesktop;
        break;
      default:
        console.warn('Некорректное сочетание стартовых и конечных точек');
    }
    return result;
  }, [isMobile, isTablet, isDesktop]);

  return showChildren ? children : null;
};
