import React from 'react';
import { Container } from '@carnica/smart-kit';
import classNames from 'classnames/bind';

import { TitleWithNavigation } from 'pages/MainPage/ui';
import { CardsBlock } from 'pages/MainPage/components/CardsBlock';
import { TariffCardBlock } from 'pages/MainPage/blocks/TariffCardBlock';
import { ETariffBlockType } from 'pages/MainPage/types';
import { Devices } from 'pages/MainPage/components/Devices';
import { Section } from 'pages/MainPage/layout/Section';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { Anchor } from 'pages/MainPage/components/Anchor';
import { AdaptivityContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { BlockWrapper } from 'pages/MainPage/layout/BlockWrapper';
import { CardsBlockProps } from 'pages/MainPage/components/CardsBlock/types';
import { IDevice } from 'pages/MainPage/components/Devices/types';
import { TitleWithNavigationProps } from 'pages/MainPage/ui/TitleWithNavigation/types';
import { BannerCarouselProps } from 'pages/MainPage/blocks/BannerCarouselBlock/types';
import { BannerCarouselBlock } from 'pages/MainPage/blocks/BannerCarouselBlock';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';

import styles from './styles.pcss';
import { connector } from './connector';

const cx = classNames.bind(styles);

interface IForDevicesProps {
  anchor: string;
  cardsBlock: CardsBlockProps;
  devices: IDevice;
  inlineBanner: BannerCarouselProps;
  titleNavigation: TitleWithNavigationProps;
}

const _ForDevices: React.FC<IForDevicesProps> = ({
  titleNavigation,
  devices,
  cardsBlock,
  anchor,
  inlineBanner,
}) => {
  const isMobile = useIsMobileDimension();
  return (
    <>
      <Anchor anchorId={anchor} />
      <Metric
        asChild
        data={titleNavigation.title}
        events={{ commonMetric: ['handleBlockView'] }}
        mode="viewport"
        once
        threshold={0.2}
      >
        <Section
          heading={
            <TitleWithNavigation
              items={titleNavigation.items}
              onNavigationItemClick={(item) =>
                commonMetric.handleBlockNavClick(item.text, titleNavigation.title)
              }
              title={titleNavigation.title}
            />
          }
          style={{ marginBottom: 0 }}
        >
          <BlockWrapper>
            <Container.Flex direction="column" gap={isMobile ? 4 : 60}>
              <AdaptivityContainer minWidth={1025}>
                <Container.ColumnGrid className={cx('grid-block')} template={['1fr', '1fr']}>
                  <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
                  <TariffCardBlock blockType={ETariffBlockType.forDevices} />
                </Container.ColumnGrid>
              </AdaptivityContainer>
              <AdaptivityContainer maxWidth={1024} minWidth={801}>
                <Container.Flex direction="column" gap={20}>
                  <TariffCardBlock blockType={ETariffBlockType.forDevices} />
                  <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
                </Container.Flex>
              </AdaptivityContainer>
              <AdaptivityContainer maxWidth={800}>
                <Container.Flex direction="column" gap={4}>
                  <TariffCardBlock blockType={ETariffBlockType.forDevices} />
                  <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
                </Container.Flex>
              </AdaptivityContainer>
              {inlineBanner?.slides && (
                <BannerCarouselBlock {...inlineBanner} sectionTitle={titleNavigation.title} />
              )}
            </Container.Flex>
          </BlockWrapper>
          <BlockWrapper>
            <Devices {...devices} blockTitle={titleNavigation.title} />
          </BlockWrapper>
        </Section>
      </Metric>
    </>
  );
};

export const ForDevices = connector(_ForDevices);
