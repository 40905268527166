import React from 'react';
import classNames from 'classnames/bind';
import { Banner as BannerUI, Button, Tag, Text } from '@carnica/ui-kit';

import { Banner } from 'pages/MainPage/ui/Banner';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { ButtonArrow } from 'pages/MainPage/ui/ButtonArrow';
import { Metric } from 'pages/MainPage/metric';

import { AdaptivityContainer } from '../../utils/ResponsiveContainers';
import { BannerCarouselProps } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO:В будущем надо такие компоненты учиться делать полностью на коннекторах, чтобы все пропсы были зашиты и брались из стора не в компоненте - а в отдельных вызовах стора
export const BannerCarouselBlock: React.FC<BannerCarouselProps> = ({
  slides,
  duration,
  sectionTitle,
}) => {
  return (
    <div className={cx('carousel')}>
      <AdaptivityContainer minWidth={801}>
        <BannerUI
          autoPlay
          className={cx('carousel-swiper')}
          duration={duration}
          infinite
          intersectionActivate={1}
          loop
          swiperOptions={{
            slideClass: cx('group-slide'),
          }}
          withControls={slides?.length > 1}
        >
          {slides?.map((item, index) => (
            <Metric
              data={JSON.stringify({
                bannerTitle: item.content.title?.text,
                bannerDescription: item.content.subtitle?.text,
                bannerUrl: item.link?.href,
                bannerId: index + 1,
                bannerPosition: `${index + 1}/${slides.length}`,
                blockTitle: sectionTitle,
              })}
              events={{ commonMetric: ['handleInlineBannerView'] }}
              mode="viewport"
              once
              threshold={0.2}
            >
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.content.title?.text,
                  bannerDescription: item.content.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                  blockTitle: sectionTitle,
                })}
                events={{ commonMetric: ['handleInlineBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.content.title?.text}
                  href={item.link?.href}
                  key={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <Banner.DesktopBanner
                    action={
                      item.actions && (
                        <Metric
                          asChild
                          data={JSON.stringify({
                            bannerTitle: item.content.title?.text,
                            buttonText: item.actions.label,
                            bannerUrl: item.link?.href,
                            bannerId: index + 1,
                            bannerDescription: item.content.subtitle?.text,
                            bannerPosition: `${index + 1}/${slides.length}`,
                            blockTitle: sectionTitle,
                          })}
                          events={{ commonMetric: ['handleInlineBannerButton'] }}
                          mode="click"
                        >
                          <Button
                            href={item.actions.href}
                            size="m"
                            tagName="a"
                            target={MenuUrlTarget.get(item.actions.isBlankUrlTarget as boolean)}
                            view={item.actions.view}
                          >
                            {item.actions.label}
                          </Button>
                        </Metric>
                      )
                    }
                    legal={
                      item.legal && (
                        <Text color={item.legal.color} variant={item.legal.variant}>
                          {item.legal.text}
                        </Text>
                      )
                    }
                    style={{
                      backgroundImage: `url(${item.img.desktop.url})`,
                      backgroundPosition: item.img.desktop?.position,
                      backgroundSize: item.img.desktop?.size,
                    }}
                  >
                    {item.content.tag && (
                      <Tag size={item.content.tag.size} view={item.content.tag.view}>
                        {item.content.tag.label}
                      </Tag>
                    )}
                    {item.content.titleImage && <img alt="" src={item.content.titleImage} />}
                    {item.content.title && (
                      <Text color={item.content.title.color} variant={item.content.title.variant}>
                        {item.content.title.text}
                      </Text>
                    )}
                    {item.content.subtitle && (
                      <Text
                        color={item.content.subtitle.color}
                        variant={item.content.subtitle.variant}
                      >
                        {item.content.subtitle.text}
                      </Text>
                    )}
                    {item.content.actionButton && (
                      <Metric
                        asChild
                        data={JSON.stringify({
                          bannerTitle: item.content.title?.text,
                          buttonText: item.content.actionButton.label,
                          bannerUrl: item.link?.href,
                          bannerDescription: item.content.subtitle?.text,
                          bannerId: index + 1,
                          bannerPosition: `${index + 1}/${slides.length}`,
                          blockTitle: sectionTitle,
                        })}
                        events={{ commonMetric: ['handleInlineBannerButton'] }}
                        mode="click"
                      >
                        <Button
                          href={item.content.actionButton.href}
                          size="m"
                          tagName="a"
                          target={MenuUrlTarget.get(
                            item.content.actionButton.isBlankUrlTarget as boolean,
                          )}
                          view={item.content.actionButton.view}
                        >
                          {item.content.actionButton.label}
                        </Button>
                      </Metric>
                    )}
                    {item.content.actionButtonArrow && (
                      <Metric
                        asChild
                        data={JSON.stringify({
                          bannerTitle: item.content.title?.text,
                          buttonText: item.content.actionButtonArrow.label,
                          bannerDescription: item.content.subtitle?.text,
                          bannerUrl: item.link?.href,
                          bannerId: index + 1,
                          bannerPosition: `${index + 1}/${slides.length}`,
                          blockTitle: sectionTitle,
                        })}
                        events={{ commonMetric: ['handleInlineBannerButton'] }}
                        mode="click"
                      >
                        <ButtonArrow {...item.content.actionButtonArrow}>
                          <Text
                            color={item.content.actionButtonArrow.textColor}
                            variant="body-accent-small"
                          >
                            {item.content.actionButtonArrow.label}
                          </Text>
                        </ButtonArrow>
                      </Metric>
                    )}
                  </Banner.DesktopBanner>
                </a>
              </Metric>
            </Metric>
          ))}
        </BannerUI>
      </AdaptivityContainer>
      <AdaptivityContainer maxWidth={800}>
        <BannerUI
          autoPlay
          className={cx('carousel-swiper')}
          duration={duration}
          infinite
          intersectionActivate={1}
          loop
          swiperOptions={{
            slideClass: cx('carousel-slide'),
          }}
          withControls={false}
        >
          {slides?.map((item, index) => (
            <Metric
              data={JSON.stringify({
                bannerTitle: item.content.title?.text,
                bannerDescription: item.content.subtitle?.text,
                bannerUrl: item.link?.href,
                bannerId: index + 1,
                bannerPosition: `${index + 1}/${slides.length}`,
                blockTitle: sectionTitle,
              })}
              events={{ commonMetric: ['handleInlineBannerView'] }}
              mode="viewport"
              once
              threshold={0.2}
            >
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.content.title?.text,
                  bannerDescription: item.content.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                  blockTitle: sectionTitle,
                })}
                events={{ commonMetric: ['handleInlineBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.content.title?.text}
                  href={item.link?.href}
                  key={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <Banner.MobileBanner
                    action={
                      item.actions && (
                        <Metric
                          asChild
                          data={JSON.stringify({
                            bannerTitle: item.content.title?.text,
                            buttonText: item.actions.label,
                            bannerDescription: item.content.subtitle?.text,
                            bannerUrl: item.link?.href,
                            bannerId: index + 1,
                            bannerPosition: `${index + 1}/${slides.length}`,
                            blockTitle: sectionTitle,
                          })}
                          events={{ commonMetric: ['handleInlineBannerButton'] }}
                          mode="click"
                        >
                          <Button
                            href={item.actions.href}
                            size="m"
                            tagName="a"
                            target={MenuUrlTarget.get(item.actions.isBlankUrlTarget as boolean)}
                            view={item.actions.view}
                          >
                            {item.actions.label}
                          </Button>
                        </Metric>
                      )
                    }
                    legal={
                      item.legal && (
                        <Text color={item.legal.color} variant={item.legal.variant}>
                          {item.legal.text}
                        </Text>
                      )
                    }
                    style={{
                      backgroundImage: `url(${item.img.mobile.url})`,
                      backgroundPosition: item.img.mobile?.position,
                      backgroundSize: item.img.mobile?.size,
                    }}
                  >
                    {item.content.tag && (
                      <Tag size={item.content.tag.size} view={item.content.tag.view}>
                        {item.content.tag.label}
                      </Tag>
                    )}
                    {item.content.titleImage && <img alt="" src={item.content.titleImage} />}
                    {item.content.title && (
                      <Text color={item.content.title.color} variant="display-small">
                        {item.content.title.text}
                      </Text>
                    )}
                    {item.content.subtitle && (
                      <Text color={item.content.subtitle.color} variant="body-medium">
                        {item.content.subtitle.text}
                      </Text>
                    )}
                    {item.content.actionButton && (
                      <Metric
                        asChild
                        data={JSON.stringify({
                          bannerTitle: item.content.title?.text,
                          buttonText: item.content.actionButton.label,
                          bannerUrl: item.link?.href,
                          bannerDescription: item.content.subtitle?.text,
                          bannerId: index + 1,
                          bannerPosition: `${index + 1}/${slides.length}`,
                          blockTitle: sectionTitle,
                        })}
                        events={{ commonMetric: ['handleInlineBannerButton'] }}
                        mode="click"
                      >
                        <Button
                          href={item.content.actionButton.href}
                          size="m"
                          tagName="a"
                          target={MenuUrlTarget.get(
                            item.content.actionButton.isBlankUrlTarget as boolean,
                          )}
                          view={item.content.actionButton.view}
                        >
                          {item.content.actionButton.label}
                        </Button>
                      </Metric>
                    )}
                    {item.content.actionButtonArrow && (
                      <Metric
                        asChild
                        data={JSON.stringify({
                          bannerTitle: item.content.title?.text,
                          buttonText: item.content.actionButtonArrow.label,
                          bannerUrl: item.link?.href,
                          bannerDescription: item.content.subtitle?.text,
                          bannerId: index + 1,
                          bannerPosition: `${index + 1}/${slides.length}`,
                          blockTitle: sectionTitle,
                        })}
                        events={{ commonMetric: ['handleInlineBannerButton'] }}
                        mode="click"
                      >
                        <ButtonArrow {...item.content.actionButtonArrow}>
                          <Text
                            color={item.content.actionButtonArrow.textColor}
                            variant="body-accent-small"
                          >
                            {item.content.actionButtonArrow.label}
                          </Text>
                        </ButtonArrow>
                      </Metric>
                    )}
                  </Banner.MobileBanner>
                </a>
              </Metric>
            </Metric>
          ))}
        </BannerUI>
      </AdaptivityContainer>
    </div>
  );
};
