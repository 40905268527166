import { compose } from '@beef/redux';
import React from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { RootState } from 'pages/MainPage/types';
import { selectTaps } from 'pages/MainPage/store/slices/help/selectors';
import { ActionSearch } from 'pages/MainPage/store/slices/help/actions';

import { ChipsBlock } from './ChipsBlock';

const mapStateToProps = (state: RootState) => ({
  taps: selectTaps(state),
  shouldRender: !!selectTaps(state)?.length,
});

const mapDispatchToProps = {
  onClick: ActionSearch.fetchSearchStart,
};
export const ChipsBlockRC = compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(ChipsBlock);
