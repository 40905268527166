import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit';
import { Container } from '@carnica/smart-kit';

import { ResponsiveContainer } from 'pages/MainPage/utils/ResponsiveContainers';

import { StoresIcons } from '../StoreIcons';
import ArrowIcon from './assets/Arrow.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface IAppBlock {
  icons: {
    appGallery: string;
    appStore: string;
    appStoreFull: string;
    googleStore: string;
    ruStore: string;
  };
  qrCodeImage: string;
  storeLinks: {
    appGallery: string;
    appStore: string;
    googlePlay: string;
    ruStore: string;
  };
  texts: {
    downloadApp: string;
    scanQr: string;
  };
}

export const AppBlock: React.FC<IAppBlock> = ({ storeLinks, icons, texts, qrCodeImage }) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('app')}>
      <Text className={cx('title')} size="s">
        {texts.downloadApp}
      </Text>
      <Container.Flex className={cx('app__install')} gap={isMobile ? 16 : 20}>
        <ResponsiveContainer end="desktop" start="tablet">
          <img
            alt="qr-code"
            className={cx('app__install-qr')}
            height={120}
            src={qrCodeImage}
            width={120}
          />
        </ResponsiveContainer>
        <Container.Flex className={cx('app__stores')} direction="column" gap={16}>
          <StoresIcons blockTitle={texts.downloadApp} icons={icons} storeLinks={storeLinks} />
          <ResponsiveContainer end="desktop" start="tablet">
            <Container.Flex className={cx('app__install-text')} direction="column" gap={4}>
              <Text color="secondary" variant="caption-medium">
                {texts.scanQr}
              </Text>
              <ArrowIcon />
            </Container.Flex>
          </ResponsiveContainer>
        </Container.Flex>
      </Container.Flex>
    </div>
  );
};
