import React from 'react';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { IButton } from 'pages/MainPage/blocks/EthernetCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Кнопка подключения */
export const ConnectButton: React.FC<IButton> = ({ href, text, ...otherProps }) => {
  return (
    <Button className={cx('button')} href={href} size="m" tagName="a" {...otherProps}>
      {text}
    </Button>
  );
};
