import { EInputErrorTextConstant } from './constants';
import { getConverter } from './converter';
import {
  InputState,
  getInputState,
  getInputTexts,
  getInputValue,
  getIsLoading,
  onInputChange,
  setInputState,
} from './store';
import { TInputErrorTexts, TInputProps, TInputState } from './types';

const getPropsFromState = ({ value, error, isLoading }: TInputState): Partial<TInputProps> => {
  return {
    value,
    error,
    disabled: isLoading,
  };
};

export const composeTest = () => {
  const state = InputState;

  const converter = getConverter({
    setIsLoading: (isLoading: boolean) => {
      state.setPartial({ isLoading });
    },
    setError: (error: string) => {
      state.setPartial({ error });
    },
    setInputValue: (value: string | undefined) => {
      state.setPartial({ value });
    },
    getTexts: () => ({
      empty: 'введите номер',
      incomplete: 'введите номер полностью',
    }),
    getInputValue: () => '',
    getIsLoading: () => getIsLoading(state.getState()),
  });

  const getState = () => {
    return state.getState();
  };

  return { getState, converter, getPropsFromState };
};

export const composeRoot = () => {
  const getTexts = (): TInputErrorTexts => {
    const texts = getInputTexts();

    return {
      empty: texts.empty ?? EInputErrorTextConstant.Empty,
      incomplete: texts.incomplete ?? EInputErrorTextConstant.Incomplete,
      beeline: texts.beeline ?? EInputErrorTextConstant.Beeline,
    };
  };

  const converter = getConverter({
    setIsLoading: (isLoading: boolean) => {
      setInputState({ isLoading });
    },
    getIsLoading: () => getIsLoading(getInputState()),
    setError: (error: string) => {
      setInputState({ error });
    },
    setInputValue: (value: string | undefined) => {
      setInputState({ value });
    },
    getTexts,
    getInputValue: () => getInputValue(getInputState()),
  });

  const onStateChange = (cb: (state: Partial<TInputProps>) => void) => {
    return onInputChange((state) => cb(getPropsFromState(state)));
  };

  return { converter, onStateChange };
};
