import { compose } from '@beef/redux';
import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import {
  selectCurrentOther,
  selectIsRenderRecentlyBlock,
  withProviderOthersCardsMapper,
} from 'pages/MainPage/store/slices/help/selectors';
import { RootState } from 'pages/MainPage/types';
import { TITLES_BLOCK } from 'pages/MainPage/blocks/HelpBlock/ulils/constants';
import { CellCard, CommonBlock } from 'pages/MainPage/blocks/HelpBlock/blocks/CommonBlock';
import { withMetric } from 'pages/MainPage/metric';
import { withAnalyticsCategory } from 'pages/MainPage/blocks/HelpBlock/hocs/withAnalyticsCategory';

export const RecommendationBlock = compose<FC<{ children?: ReactNode; shouldRender?: boolean }>>(
  connect((state: RootState) => ({
    title: TITLES_BLOCK.recently,
    shouldRender: selectIsRenderRecentlyBlock(state),
  })),
  withProviderOthersCardsMapper,
  withRenderFlag,
  withMetric({
    asChild: true,
    events: { helpBlockMetric: ['handleViewRecently'] },
    mode: 'viewport',
    threshold: 0.5,
    once: true,
  }),
)(CommonBlock);

export const RecommendationCard = compose<FC>(
  connect((state: RootState) => ({
    item: selectCurrentOther(state),
  })),
  withAnalyticsCategory(TITLES_BLOCK.recently),
)(CellCard);
