import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { CardsCarouselProps } from 'pages/MainPage/ui/CardsCarousel/types';
import { ButtonArrow, CardsCarousel } from 'pages/MainPage/ui';
import { commonMetric } from 'pages/MainPage/metric/common';
import { Metric } from 'pages/MainPage/metric';

import { Desktop, Mobile } from '../../utils/ResponsiveContainers';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface VacanciesBlockProps {
  link: {
    href: string;
    isBlankUrlTarget: boolean;
    label: string;
  };
  slides: CardsCarouselProps[];
  title: string;
}

export const VacanciesBlock: React.FC<VacanciesBlockProps> = ({ link, title, slides }) => {
  return (
    <div className={cx('section')}>
      <div className={cx('heading')}>
        <Text className={cx('heading-title')} color="primary" variant="headline-small">
          {title}
        </Text>
        <Desktop>
          <Metric
            asChild
            data={JSON.stringify({ blockTitle: title, buttonText: link.label })}
            events={{ commonMetric: ['handleButtonClick'] }}
            mode="click"
          >
            <ButtonArrow
              arrowFill="primary"
              arrowPosition="top-right"
              href={link.href}
              isBlankUrlTarget={link.isBlankUrlTarget}
            >
              <Text color="secondary" variant="body-small">
                {link.label}
              </Text>
            </ButtonArrow>
          </Metric>
        </Desktop>
      </div>
      <div className={cx('carousel')}>
        <CardsCarousel
          onCardClick={(cardTitle) =>
            commonMetric.handleCardClick(
              JSON.stringify({
                blockTitle: title,
                elementTitle: cardTitle,
              }),
            )
          }
          slides={slides}
        />
      </div>
      <Mobile>
        <Metric
          asChild
          data={JSON.stringify({ blockTitle: title, buttonText: link.label })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <ButtonArrow
            arrowFill="primary"
            arrowPosition="top-right"
            className={cx('actions')}
            href={link.href}
            isBlankUrlTarget={link.isBlankUrlTarget}
          >
            <Text color="secondary" variant="body-small">
              {link.label}
            </Text>
          </ButtonArrow>
        </Metric>
      </Mobile>
    </div>
  );
};
