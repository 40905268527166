import { withZones } from '@beef/react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import PaymentIcons from '../assets/payments.svg';
import { EChild } from '../constants';
import styles from './styles.pcss';
import { TTopCardLayoutProps, TZones } from '../types';

const cn = classNames.bind(styles);

export const CardWrapper: React.FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  return (
    <div className={cn('top-card')}>
      <div className={cn('form')}>{children}</div>
    </div>
  );
};

export const TopCardLayout = withZones(Object.keys(EChild) as EChild[])(({
  textProps,
  zones,
}: TTopCardLayoutProps & TZones) => {
  return (
    <CardWrapper>
      <Text variant="body-large" {...textProps} className={cn('title')} />
      <div className={cn('input-container')}>
        {zones[EChild.TopCardInput]}
        <div className={cn('button-container')}>
          {zones[EChild.TopCardButton]}
          <PaymentIcons />
        </div>
      </div>
    </CardWrapper>
  );
});
