import React from 'react';

import { EthernetCardBlockProps } from './types';
import { Section } from './components/Section';
import { TariffCard } from './components/TariffCard';
import { ChipsBlock } from './components/ChipsBlock';
import { Title } from './components/Title';

export const EthernetCardBlock: React.FC<EthernetCardBlockProps> = ({ title, card, chips }) => {
  return (
    <Section>
      {title && <Title>{title}</Title>}
      {card && <TariffCard {...card} blockTitle={title} />}
      {chips && <ChipsBlock {...chips} blockTitle={title} />}
    </Section>
  );
};
