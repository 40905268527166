import React, { FC } from 'react';

import { SkeletonTaps } from './SkeletonTaps';
import { SkeletonCell } from './SkeletonCell';

type Props = {
  children?: React.ReactNode;
  isLoading: boolean;
};

export const SkeletonLayout: FC<Props> = ({ children, isLoading }) => {
  if (!isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div>
      <SkeletonTaps />
      <SkeletonCell />
    </div>
  );
};
